#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff;
  display: table;
  text-align: center;
}

.loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px;
}

.loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #39b35d;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  margin: 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

a:hover,
a:active {
  color: #39b35d;
  text-decoration: none;
}

.bg-theme {
  background-color: #39b35d;
}

.bg-theme-90 {
  background: rgba(73, 192, 208, 0.9);
}

.bg-theme-80 {
  background: rgba(73, 192, 208, 0.8);
}

.theme-overlay[data-overlay-dark]:before {
  background: #39b35d;
}

.text-theme-color {
  color: #39b35d;
}

.text-content-big p {
  font-weight: 400;
  line-height: 200%;
  margin-bottom: 25px;
  font-size: 15px;
}

.shadow-primary {
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.red {
  color: red;
}

.line-through {
  text-decoration: line-through;
}

.readmore {
  font-weight: 700;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.readmore:after {
  content: "\f105";
  font-size: 16px;
  vertical-align: top;
  font-family: Font awesome\5 Free;
  padding-left: 8px;
  vertical-align: middle;
}

.readmore:hover {
  color: #39b35d;
}

.left-title {
  position: relative;
}

.left-title:before {
  content: "";
  background: #39b35d;
  width: 15px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 10px;
}

.left-title.white:before {
  background: #fff;
}

@media screen and (max-width: 575px) {
  .no-mobile-margin-15px-bottom {
    margin-bottom: 0 !important;
  }

  .mobile-margin-15px-bottom {
    margin-bottom: 15px;
  }

  .mobile-margin-20px-bottom {
    margin-bottom: 20px;
  }
}

.primary-list {
  padding-left: 0;
}

.primary-list li {
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  list-style-type: none;
  color: #222;
}

.primary-list li i {
  color: #39b35d;
  padding-right: 10px;
}

.white-list {
  padding-left: 0;
}

.white-list li {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  list-style-type: none;
}

.white-list li i {
  color: #39b35d;
  padding-right: 15px;
}

.list-style-16 li:after {
  color: #39b35d;
}

.white-list {
  padding-left: 0;
}

.white-list li {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  list-style-type: none;
}

.white-list li i {
  color: #39b35d;
  padding-right: 15px;
}

.scroll-to-top {
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 50%;
  background: #000;
  border: 1px solid #2a2a2a;
  width: 35px;
  height: 35px;
  line-height: 30px;
  z-index: 9999;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.scroll-to-top i {
  color: #fff;
}

.scroll-to-top:hover {
  color: #232323;
  background: #fff;
}

.scroll-to-top:hover i {
  color: #232323;
}

.scroll-to-top:visited {
  color: #232323;
  text-decoration: none;
}

.butn {
  background: #232323;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 12px 30px;
  line-height: normal;
  font-weight: 500;
  text-transform: none !important;
  position: relative;
  z-index: 9999;
  display: inline-block;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.butn span {
  z-index: 9;
  position: relative;
}

.butn:hover {
  color: #fff;
  border-radius: 3px;
}

.butn:after {
  content: "";
  position: absolute;
  border-radius: 4px;
  bottom: 6px;
  left: 0;
  height: 2px;
  -webkit-transition: width 0.4s;
  -o-transition: width 0.4s;
  transition: width 0.4s;
  width: 0;
  background: #39b35d;
  height: 100%;
  top: 0;
  z-index: 1;
}

.butn:hover:after {
  width: 100%;
  border-radius: 3px;
}

.butn.white {
  background: #fff;
  color: #232323;
}

.butn.white:hover {
  color: #fff;
  border-radius: 3px;
}

.butn.white:after {
  background: #232323;
  border-radius: 3px;
}

.butn.theme {
  background: #39b35d;
  color: #fff;
}

.butn.theme span {
  color: #fff;
}

.butn.theme:hover {
  color: #fff;
  border-radius: 3px;
}

.butn.theme:after {
  background: #232323;
  border-radius: 3px;
}

.butn.theme.white-hover:hover {
  color: #232323;
}

.butn.theme.white-hover:hover span {
  color: #232323;
}

.butn.theme.white-hover:after {
  background: #fff;
  border-radius: 3px;
}

.butn.small {
  padding: 8px 20px;
}

.butn.medium {
  padding: 10px 20px;
}

.btn-style2 {
  display: inline-block;
  font-size: 14px;
  padding: 12px 20px;
  border: 2px solid #222;
  color: #232323;
  letter-spacing: 0.5px;
  line-height: 26px;
  border-radius: 0;
  text-transform: uppercase;
  width: auto;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.btn-style2:hover,
.btn-style2:active {
  background: #39b35d;
  color: #fff;
  border-color: #39b35d;
}

.btn-style2:focus {
  background: #39b35d;
  color: #fff;
  box-shadow: none;
  border-color: #39b35d;
}

.btn-style2.white {
  border-color: #fff;
  color: #fff;
}

.btn-style2.white:hover {
  border-color: #39b35d;
}

.btn-style2 span {
  text-shadow: none !important;
  color: #fff !important;
}

.butn-style3 {
  text-align: center;
  display: inline-block;
  font-size: 14px;
  padding: 9px 22px;
  min-width: 150px;
  background: transparent;
  border: 2px solid #39b35d;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #39b35d;
  letter-spacing: 0.5px;
  line-height: inherit;
  width: auto;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.butn-style3:hover {
  background: #39b35d;
  color: #fff;
}

.butn-style3.theme {
  background: #39b35d;
  border: 2px solid #39b35d;
  color: #fff;
}

.butn-style3.theme:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.butn-style3.white {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.butn-style3:hover {
  background: #39b35d;
  color: #fff;
  border-color: #39b35d;
}

.btn-style4 {
  display: inline-block;
  border: 2px solid transparent;
  line-height: inherit;
  text-transform: uppercase;
  font-weight: 600;
  min-width: 175px;
  z-index: 1;
  overflow: hidden;
}

.btn-style4.btn-very-small {
  font-size: 9px;
  padding: 1px 17px;
  line-height: 22px;
}

.btn-style4.btn-small {
  font-size: 11px;
  padding: 4px 24px;
}

.btn-style4.btn-medium {
  font-size: 12px;
  padding: 12px 25px 11px;
}

.btn-style4.btn-large {
  font-size: 13px;
  padding: 9px 34px;
  line-height: 25px;
}

.btn-style4.btn-extra-large {
  font-size: 15px;
  padding: 12px 40px 13px;
  line-height: 25px;
}

.btn-style4 {
  padding: 15px 10px;
  border-radius: 0;
  border: none;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
  background-color: #39b35d;
  display: inline-block;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.btn-style4:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 500%;
  height: 1000%;
  background: rgba(255, 255, 255, 0.3);
  z-index: -1;
  transform-origin: 0% 0%;
  transform: translateX(calc(20% - 20px)) translateY(10%) rotate(-45deg);
  transition: transform 0.3s;
}

.btn-style4:hover:after {
  transform: translateY(10%) translateX(-20px) rotate(-45deg);
}

.btn-style4 i {
  margin-left: 6px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.btn-style4:hover,
.btn-style4:active,
.btn-style4:focus {
  background-color: #000 !important;
  color: #fff !important;
  box-shadow: none !important;
  border: none !important;
}

.btn-style4.min-width-auto {
  min-width: auto;
}

.btn-style4.btn-primary {
  background-color: #000;
  color: #fff;
}

.btn-style4.btn-primary:hover {
  background-color: #39b35d !important;
  color: #fff !important;
  box-shadow: none !important;
  border: none !important;
}

.btn-primary:active,
.btn-style4.btn-primary:focus {
  background-color: #39b35d !important;
  color: #fff !important;
  box-shadow: none !important;
  border: none !important;
}

@media screen and (max-width: 991px) {
  .butn {
    padding: 10px 28px;
  }

  .butn.small {
    padding: 7px 16px;
  }

  .butn.medium {
    padding: 8px 18px;
  }

  .btn-style2 {
    padding: 10px 18px;
  }

  .butn-style3 {
    min-width: 130px;
  }

  .btn-style4 {
    padding: 10px 10px;
    min-width: 150px;
    font-size: 13px;
    letter-spacing: 1px;
  }

  .btn-style4.btn-medium {
    padding: 10px 22px 9px;
  }

  .btn-style4.btn-large {
    padding: 8px 32px;
    line-height: 24px;
  }

  .btn-style4.btn-extra-large {
    padding: 10px 36px 12px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .butn {
    padding: 8px 26px;
  }

  .butn.small {
    padding: 6px 14px;
  }

  .butn.medium {
    padding: 6px 16px;
  }

  .btn-style2 {
    padding: 8px 14px;
  }

  .butn-style3 {
    min-width: inherit;
  }

  .btn-style4 {
    padding: 8px 8px;
    min-width: 140px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .btn-style4.btn-medium {
    padding: 10px 18px 8px;
  }

  .btn-style4.btn-large {
    font-size: 12px;
    padding: 8px 32px;
    line-height: 24px;
  }

  .btn-style4.btn-extra-large {
    font-size: 14px;
    padding: 10px 36px 12px;
    line-height: 24px;
  }

  .xs-default-gutter {
    margin: 0 -15px;
  }

  .xs-default-gutter > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.section-heading {
  margin-bottom: 50px;
  text-align: center;
}

.section-heading.half {
  margin-bottom: 30px;
}

.section-heading h1,
.section-heading h2,
.section-heading h3,
.section-heading h4,
.section-heading h5,
.section-heading h6 {
  position: relative;
  padding-bottom: 10px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  line-height: 140%;
}

.section-heading h1:after,
.section-heading h2:after,
.section-heading h3:after,
.section-heading h4:after,
.section-heading h5:after,
.section-heading h6:after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #39b35d;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-heading.white h1,
.section-heading.white h2,
.section-heading.white h3,
.section-heading.white h4,
.section-heading.white h5,
.section-heading.white h6 {
  color: #fff;
}

.section-heading.grey h1,
.section-heading.grey h2,
.section-heading.grey h3,
.section-heading.grey h4,
.section-heading.grey h5,
.section-heading.grey h6 {
  color: #b7b7b7;
}

.section-heading.grey h1:after,
.section-heading.grey h2:after,
.section-heading.grey h3:after,
.section-heading.grey h4:after,
.section-heading.grey h5:after,
.section-heading.grey h6:after {
  background: #b7b7b7;
}

.section-heading.left {
  text-align: left;
}

.section-heading.left h1,
.section-heading.left h2,
.section-heading.left h3,
.section-heading.left h4,
.section-heading.left h5,
.section-heading.left h6 {
  padding-bottom: 15px;
}

.section-heading.left h1:after,
.section-heading.left h2:after,
.section-heading.left h3:after,
.section-heading.left h4:after,
.section-heading.left h5:after,
.section-heading.left h6:after {
  margin: 0;
  left: 0;
}

.section-heading strong {
  font-weight: 600;
}

.section-heading.white p {
  color: #fff;
}

.section-heading p {
  font-size: 15px;
  font-weight: 400;
  padding-top: 15px;
  margin: 0 auto;
}

.section-heading span {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #39b35d;
  line-height: 200%;
  margin-bottom: 10px;
}

.section-heading.white span {
  color: #fff;
}

.section-heading.grey span {
  color: #b7b7b7;
}

.section-heading .badge {
  display: inline-block;
  padding: 7px 19px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 14px;
  color: #39b35d;
  border: 1px solid #39b35d;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

.title-style2 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 0;
}

.title-style2:after {
  content: "";
  width: 60px;
  height: 2px;
  background: #39b35d;
  position: absolute;
  bottom: 0;
  left: calc(50% - 30px);
}

.title-style2:before {
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 250px;
}

.section-heading.left .title-style2 {
  left: 0;
  margin: 0;
}

.section-heading.left .title-style2:before {
  width: 125px;
  left: 0;
  margin: 0;
}

.section-heading.left .title-style2:after {
  width: 50px;
  left: 37px;
}

h1.title-style2.white,
h2.title-style2.white,
h3.title-style2.white,
h4.title-style2.white,
h5.title-style2.white,
h6.title-style2.white {
  color: #fff;
}

.section-heading .title-style2.white:after {
  background: #39b35d;
}

.section-heading .title-style2.white:before {
  background: rgba(255, 255, 255, 0.8);
}

.section-heading.title-style3 {
  text-align: left;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.section-heading.title-style3 h1,
.section-heading.title-style3 h2,
.section-heading.title-style3 h3,
.section-heading.title-style3 h4,
.section-heading.title-style3 h5,
.section-heading.title-style3 h6 {
  padding: 0;
  margin: 0;
}

.section-heading.title-style3 h1:after,
.section-heading.title-style3 h2:after,
.section-heading.title-style3 h3:after,
.section-heading.title-style3 h4:after,
.section-heading.title-style3 h5:after,
.section-heading.title-style3 h6:after {
  content: none;
}

.title-overlay {
  mix-blend-mode: lighten;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.85);
  margin: 0;
}

.section-heading.title-style4 h1,
.section-heading.title-style4 h2,
.section-heading.title-style4 h3,
.section-heading.title-style4 h4,
.section-heading.title-style4 h5,
.section-heading.title-style4 h6 {
  text-transform: uppercase;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.section-heading.title-style4 p {
  padding-top: 0;
}

.section-heading.title-style4 span {
  color: #39b35d;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.section-heading.title-style4 h1:after,
.section-heading.title-style4 h2:after,
.section-heading.title-style4 h3:after,
.section-heading.title-style4 h4:after,
.section-heading.title-style4 h5:after,
.section-heading.title-style4 h6:after {
  content: none;
}

.section-heading.title-style5 h1,
.section-heading.title-style5 h2,
.section-heading.title-style5 h3,
.section-heading.title-style5 h4,
.section-heading.title-style5 h5,
.section-heading.title-style5 h6 {
  padding: 0;
  margin: 0;
}

.section-heading.title-style5.white h1,
.section-heading.title-style5.white h2,
.section-heading.title-style5.white h3,
.section-heading.title-style5.white h4,
.section-heading.title-style5.white h5,
.section-heading.title-style5.white h6 {
  color: #fff;
}

.section-heading.title-style5 h1:after,
.section-heading.title-style5 h2:after,
.section-heading.title-style5 h3:after,
.section-heading.title-style5 h4:after,
.section-heading.title-style5 h5:after,
.section-heading.title-style5 h6:after {
  content: none;
}

.section-heading.title-style5 .separator-left,
.section-heading.title-style5 .separator-right {
  height: 1px;
  position: absolute;
  top: 50%;
  width: 70px;
  margin-top: -1px;
  margin-bottom: 0;
}

.section-heading.title-style5 .separator-left {
  left: -80px;
}

.section-heading.title-style5 .separator-right {
  right: -80px;
}

.section-heading.title-style5 .square {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #39b35d;
  display: inline-block;
  vertical-align: text-bottom;
  position: relative;
}

.section-heading.title-style5.left .square .separator-left {
  display: none;
}

@media screen and (max-width: 1199px) {
  .section-heading.title-style4 span {
    font-size: 30px;
  }
}

@media screen and (max-width: 991px) {
  .section-heading {
    margin-bottom: 30px;
  }

  .section-heading p {
    padding-top: 10px;
    font-size: 14px;
  }

  .section-heading.title-style4 span {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .section-heading.title-style4 span {
    font-size: 28px;
  }
}

.section-heading.title-style6 h1,
.section-heading.title-style6 h2,
.section-heading.title-style6 h3,
.section-heading.title-style6 h4,
.section-heading.title-style6 h5,
.section-heading.title-style6 h6 {
  position: relative;
  padding-bottom: 10px;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  line-height: 140%;
  text-transform: inherit;
}

.section-heading.title-style6 h1:after,
.section-heading.title-style6 h2:after,
.section-heading.title-style6 h3:after,
.section-heading.title-style6 h4:after,
.section-heading.title-style6 h5:after,
.section-heading.title-style6 h6:after {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background: #39b35d;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-heading.title-style6 span {
  color: #232323;
  font-weight: 600;
  font-size: 32px;
  line-height: normal;
}

.section-heading.title-style6.white span {
  color: #fff;
}

.section-heading.title-style6.left {
  text-align: left;
}

.section-heading.title-style6.left h1:after,
.section-heading.title-style6.left h2:after,
.section-heading.title-style6.left h3:after,
.section-heading.title-style6.left h4:after,
.section-heading.title-style6.left h5:after,
.section-heading.title-style6.left h6:after {
  margin: 0;
}

@media screen and (max-width: 1199px) {
  .section-heading.title-style6 span {
    font-size: 30px;
  }
}

@media screen and (max-width: 991px) {
  .section-heading.title-style6 span {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .section-heading.title-style6 span {
    font-size: 26px;
  }
}

.inner-title h1,
.inner-title h2,
.inner-title h3,
.inner-title h4,
.inner-title h5,
.inner-title h6 {
  font-size: 18px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 600;
}

.inner-title h1:after,
.inner-title h2:after,
.inner-title h3:after,
.inner-title h4:after,
.inner-title h5:after,
.inner-title h6:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background: #39b35d;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.inner-title.half h1,
.inner-title.half h2,
.inner-title.half h3,
.inner-title.half h4,
.inner-title.half h5,
.inner-title.half h6 {
  margin-bottom: 15px;
}

.title-style8 {
  padding-bottom: 25px;
  padding-left: 40px;
  padding-top: 25px;
  position: relative;
  font-weight: 700;
  z-index: 1;
}

.title-style8:before {
  border: 5px solid #f3f3f3;
  bottom: 0;
  content: "";
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 150px;
  z-index: -1;
}

.top-bar-info {
  display: inline-block;
  vertical-align: middle;
}

.top-bar-info ul {
  margin-bottom: 0;
}

.top-bar-info li {
  font-weight: 500;
  color: #fff;
  list-style-type: none;
  font-size: 13px;
  padding: 0 5px 0;
  display: inline-block;
  margin-bottom: 0;
}

#top-bar {
  display: block;
  position: relative;
  z-index: 999;
  background: #1c1c1c;
  padding: 7px 0;
}

.top-bar-info li i {
  font-size: 16px;
  color: #39b35d;
  margin-right: 8px;
  margin-top: 0;
  display: inline-block;
  vertical-align: text-bottom;
}

.top-social-icon {
  padding: 0;
  float: right;
  margin: 0;
}

.top-social-icon li {
  font-size: 14px;
  list-style-type: none;
  float: left;
  text-align: center;
  margin: 0;
  padding: 0 7px;
}

.top-social-icon li:last-child {
  padding-right: 0;
}

.top-social-icon li:last-child a {
  padding-right: 0;
}

.top-social-icon li a {
  color: #fff;
  line-height: 28px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 0 3px;
}

.top-social-icon li a:hover {
  color: #39b35d;
}

#top-bar.top-bar-style2 {
  background: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.top-bar-style2 .top-bar-info li {
  color: #6f6f6f;
}

.top-bar-style2 .top-bar-info li i {
  color: #6f6f6f;
}

.top-bar-style2 .top-social-icon li a {
  color: #6f6f6f;
}

.top-bar-style2 .top-social-icon li a:hover {
  color: #232323;
}

.navbar-nav li.current > a,
.navbar-nav li.active > a {
  color: #39b35d;
}

.navbar > ul > li.current > a:after {
  border-color: transparent #39b35d #39b35d transparent;
}

@media screen and (min-width: 992px) {
  .header-style1 .navbar-nav li.current > a,
  .header-style2 .navbar-nav li.current > a,
  .header-style1 .navbar-nav li.active > a,
  .header-style2 .navbar-nav li.active > a {
    color: #39b35d;
  }

  .header-style1 .navbar > ul > li.current > a:after,
  .header-style2 .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .header-style1.scrollHeader .navbar-nav li.current > a,
  .header-style2.scrollHeader .navbar-nav li.current > a,
  .header-style1.scrollHeader .navbar-nav li.active > a,
  .header-style2.scrollHeader .navbar-nav li.active > a,
  .header-style1.scrollHeader .navbar-nav li.current > a:hover,
  .header-style2.scrollHeader .navbar-nav li.current > a:hover,
  .header-style1.scrollHeader .navbar-nav li.active > a:hover,
  .header-style2.scrollHeader .navbar-nav li.active > a:hover {
    color: #39b35d;
  }

  .header-style1.scrollHeader .navbar > ul > li.current > a:after,
  .header-style2.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .header-style3 {
    border-bottom: 2px solid rgba(73, 192, 208, 0.9);
  }

  .header-style4 {
    z-index: 1000;
  }

  .header-style4 .navbar-nav li.current > a {
    color: #39b35d;
  }

  .header-style4 .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .header-style4 .navbar-nav li.active > a {
    color: #39b35d;
  }

  .header-style5 #top-bar {
    background: #39b35d;
  }

  .header-style6 .navbar-default {
    background: rgba(73, 192, 208, 0.85);
  }

  .header-style6.scrollHeader .navbar-default {
    background: #39b35d;
  }

  .header-style8 .navbar ul ul li.active > a {
    color: #39b35d;
  }

  .header-style8 .navbar-nav li.current > a,
  .header-style8 .navbar-nav li.active > a {
    color: #39b35d;
  }

  .header-style8 .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .header-style8.scrollHeader .navbar-nav > li.has-sub > a:hover {
    color: #39b35d;
  }

  .header-style8.scrollHeader .navbar-nav li.current > a {
    color: #39b35d;
  }

  .header-style8.scrollHeader .navbar-nav li.current > a:hover {
    color: #39b35d;
  }

  .header-style8.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .header-style8.scrollHeader .navbar-nav li.active > a {
    color: #39b35d;
  }

  .header-style8.scrollHeader .navbar-nav li.active > a:hover {
    color: #39b35d;
  }

  .menu_area-light .navbar-nav li.current > a,
  .menu_area-light .navbar-nav li.active > a {
    color: #39b35d;
  }

  .menu_area-light .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .menu_area-light.scrollHeader .navbar-nav li.current > a {
    color: #39b35d;
  }

  .menu_area-light.scrollHeader .navbar-nav li.current > a:hover {
    color: #39b35d;
  }

  .menu_area-light.scrollHeader .navbar > ul > li.current > a:after {
    border-color: transparent #39b35d #39b35d transparent;
  }

  .menu_area-light.scrollHeader .navbar-nav li.active > a {
    color: #39b35d;
  }

  .menu_area-light.scrollHeader .navbar-nav li.active > a:hover {
    color: #39b35d;
  }
}

@media screen and (max-width: 991px) {
  .header-style3 {
    border-bottom: 2px solid rgba(73, 192, 208, 0.9);
  }

  .header-style5 #top-bar,
  .header-style5 .navbar-toggler {
    background: #39b35d;
  }

  .header-style6 .navbar-default {
    background: rgba(73, 192, 208, 0.85);
  }

  .header-style8 .navbar ul ul li.active > a {
    color: #39b35d;
  }
}

.page-title-section {
  background-size: cover;
  background-position: center;
}

.page-title-section h1 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  color: #fff;
  margin-bottom: 0;
}

.page-title-section ul {
  margin-bottom: 0;
  margin-top: 15px;
  text-align: left;
}

.page-title-section ul li {
  display: inline-block;
}

.page-title-section ul li:last-child a {
  color: #39b35d;
}

.page-title-section ul li:after {
  content: "\f105";
  font-weight: 700;
  vertical-align: bottom;
  color: #fff;
  font-family: Font awesome\5 Free;
  padding: 0 10px;
}

.page-title-section ul li:last-child:after {
  content: none;
}

.page-title-section ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.page-title-section .active a,
.page-title-section li.active:last-child a {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .page-title-section h1 {
    font-size: 36px;
    line-height: 40px;
  }
}

@media screen and (max-width: 991px) {
  .page-title-section h1 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media screen and (max-width: 767px) {
  .page-title-section h1 {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 36px;
  }

  .page-title-section ul {
    margin-top: 5px;
  }
}

.page-title-section2 {
  padding: 250px 0 150px;
  text-align: center;
}

.page-title-section2 h1 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  color: #fff;
  margin-bottom: 0;
}

.page-title-section2 ul {
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
}

.page-title-section2 ul li {
  display: inline-block;
}

.page-title-section2 ul li:last-child a {
  color: #39b35d;
}

.page-title-section2 ul li:after {
  content: "\f105";
  font-weight: 700;
  vertical-align: middle;
  color: #fff;
  font-family: Font awesome\5 Free;
  padding: 0 5px 0 10px;
}

.page-title-section2 ul li:last-child:after {
  content: none;
}

.page-title-section2 ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.page-title-section2 .active a,
.page-title-section2 li.active:last-child a {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .page-title-section2 {
    padding: 200px 0 100px;
  }

  .page-title-section2 h1 {
    font-size: 36px;
    line-height: 42px;
  }
}

@media screen and (max-width: 991px) {
  .page-title-section2 {
    padding: 148px 0 75px;
  }

  .page-title-section2 h1 {
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .page-title-section2 ul {
    margin-top: 5px;
  }
}

.page-title-section3 h1 {
  color: #232323;
  margin-bottom: 0;
}

.page-title-section3 ul {
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
}

.page-title-section3 ul li {
  display: inline-block;
}

.page-title-section3 ul li:last-child a {
  color: #232323;
}

.page-title-section3 ul li:after {
  content: " \f152";
  color: #fff;
  font-weight: 700;
  font-family: Font awesome\5 Free;
  padding: 0 10px;
}

.page-title-section3 ul li:last-child:after {
  content: none;
}

.page-title-section3 ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .page-title-section3 ul {
    margin-top: 5px;
  }
}

.page-title-section.small h1 {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: normal;
  color: #fff;
  margin-bottom: 0;
}

.page-title-section.small ul {
  margin-bottom: 0;
  margin-top: 6px;
  text-align: left;
}

.page-title-section.small ul li {
  display: inline-block;
}

.page-title-section.small ul li:last-child a {
  color: #39b35d;
}

.page-title-section.small ul li:after {
  content: " \f152";
  color: #fff;
  font-weight: 700;
  font-family: Font awesome\5 Free;
  padding: 0 10px;
}

.page-title-section.small ul li:last-child:after {
  content: none;
}

.page-title-section.small ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .page-title-section.small h1 {
    margin-bottom: 5px;
    font-size: 24px;
  }

  .page-title-section.small ul {
    margin-top: 5px;
  }
}

.vertical-header {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 56px;
}

.vertical-header.new-work-vertical-header {
  padding-bottom: 120px;
}

.vertical-header-text {
  left: -124px;
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  text-align: center;
  transform: rotate(-90deg);
  width: 300px;
  font-size: 14px;
  text-transform: uppercase;
}

.vertical-header-text:after {
  background: #39b35d;
  content: "";
  display: inline-block;
  height: 1px;
  left: 32px;
  position: absolute;
  right: 0;
  top: 10px;
  width: 20px;
}

.vertical-header-text:before {
  background: #39b35d;
  content: "";
  display: inline-block;
  height: 1px;
  left: 247px;
  position: absolute;
  right: 0;
  top: 10px;
  width: 20px;
}

.owl-carousel .caption .overflow-hidden {
  display: inline-block;
}

.owl-carousel .caption h3 {
  font-weight: 200;
  animation-delay: 0.5s;
  position: relative;
  display: inline-block;
}

.owl-carousel .caption h1 {
  margin: 10px 0;
  font-size: 70px;
  font-weight: 500;
  text-transform: uppercase;
  animation-delay: 0.8s;
}

.owl-carousel .caption p {
  font-size: 18px;
  color: #eee;
  word-spacing: 2px;
  animation-delay: 1.2s;
}

.owl-carousel .caption span {
  display: inline-block;
  padding: 0.2em 0;
}

.owl-carousel .caption .butn {
  animation-delay: 1.4s;
}

.owl-carousel .caption .butn i {
  position: relative;
  top: 1px;
  z-index: 9;
}

.carousel-style3 .owl-carousel .owl-item img {
  width: 100%;
}

.carousel-style4 .owl-carousel .owl-item img {
  width: 100%;
}

.home-business-slider .owl-nav .owl-next {
  position: absolute;
  right: 15px;
  top: 50%;
  background: none !important;
  opacity: 0.35;
}

.home-business-slider .owl-nav .owl-next:hover {
  opacity: 1;
}

.home-business-slider .owl-nav .owl-prev {
  position: absolute;
  left: 15px;
  top: 50%;
  background: none !important;
  opacity: 0.35;
}

.home-business-slider .owl-nav .owl-prev:hover {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .half-container-banner .banner-container {
    background: rgba(255, 255, 255, 0.95);
    padding: 25px;
    border: 10px solid rgba(0, 0, 0, 0.1);
  }

  .half-banner[data-overlay-dark="0"]:before,
  .half-banner[data-overlay-light="0"]:before {
    opacity: 0.7;
  }

  .owl-carousel .caption h1 {
    font-size: 50px;
  }

  .owl-carousel .caption p {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .owl-carousel .caption h1 {
    font-size: 32px;
    margin-top: 5px;
  }

  .owl-carousel .caption h3 {
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 575px) {
  .half-container-banner .banner-container {
    border: 5px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 90%;
  }

  .owl-carousel .caption h1 {
    font-size: 20px;
    margin-top: 5px;
  }
}

.slider-fade .owl-item {
  height: 100vh;
  position: relative;
}

.slider-fade .item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.slider-fade .item .caption {
  width: 100%;
  z-index: 9;
}

.slider-fade .owl-theme .owl-dots {
  position: absolute;
  bottom: 5vh;
  width: 100%;
  left: 0;
  text-align: center;
}

.rev_slider_wrapper .tp-caption span {
  color: #39b35d;
}

.rev_slider_wrapper .butn span {
  font-size: 14px !important;
  line-height: 24px !important;
  color: #fff;
  text-shadow: none;
}

.rev_slider_wrapper .butn.white span {
  color: #232323;
}

.rev_slider_wrapper .butn.white:hover span {
  color: #fff;
}

@media screen and (min-width: 767px) {
  .custom-paragraph .slider-text p {
    width: 450px !important;
    font-size: 15px !important;
    line-height: 26px !important;
  }
}

@media screen and (min-width: 1024px) {
  .rev_slider .max-style {
    font-size: 60px !important;
    line-height: 70px !important;
  }

  .rev_slider .max-style span {
    font-size: 60px !important;
    line-height: 70px !important;
  }
}

@media screen and (max-width: 1023px) {
  .rev_slider .max-style {
    font-size: 45px !important;
    line-height: 50px !important;
  }

  .rev_slider .max-style span {
    font-size: 45px !important;
    line-height: 50px !important;
  }

  .custom-paragraph .slider-text p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

@media screen and (max-width: 766px) {
  .rev_slider .max-style {
    font-size: 30px !important;
    line-height: normal !important;
  }

  .rev_slider .max-style span {
    font-size: 30px !important;
    line-height: normal !important;
  }

  .custom-paragraph .slider-text p {
    text-align: center !important;
    font-size: 14px !important;
    line-height: 24px !important;
    width: 480px !important;
    padding: 0 15px;
  }

  .custom-controls .tp-leftarrow,
  .custom-controls .tp-rightarrow {
    width: 40px !important;
    height: 40px !important;
  }

  .custom-controls .tp-leftarrow {
    left: -15px !important;
  }

  .custom-controls .tp-rightarrow {
    left: inherit !important;
    right: -55px !important;
  }

  .custom-controls .tparrows:before {
    line-height: 20px !important;
  }
}

@media screen and (max-width: 479px) {
  .rev_slider .max-style {
    font-size: 20px !important;
    line-height: normal !important;
  }

  .rev_slider .max-style span {
    font-size: 20px !important;
    line-height: normal !important;
  }

  .custom-paragraph .slider-text p {
    width: 320px !important;
    padding: 0 15px;
  }
}

.feature-box-01 {
  background: #fff;
  border: 5px solid rgba(0, 0, 0, 0.05);
  padding: 25px 20px;
  text-align: center;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
}

.feature-box-01 p {
  margin-bottom: 10px;
}

.feature-box-01 .icon-tools {
  color: #939393;
}

.feature-box-01:hover {
  background: #ffffff;
  border: 5px solid #39b35d;
}

.feature-box-01:hover .icon-tools {
  color: #39b35d;
}

.feature-box-02 {
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.feature-box-02:hover {
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.feature-box-02:before {
  background: #39b35d;
  content: "";
  height: 0;
  left: 14px;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out 0s;
  width: 2px;
}

.feature-box-02:hover:before {
  height: 100%;
}

.feature-box-03 {
  border: 2px solid #383838;
  padding: 25px 20px;
  text-align: center;
  transition: all 0.5s ease 0s;
}

.feature-box-03 .icon-tools {
  color: #939393;
}

.feature-box-03 p {
  color: #9d9d9d;
}

.feature-box-03:hover {
  background: #39b35d;
  border: 2px solid #39b35d;
}

.feature-box-03:hover p {
  color: #fff;
}

.feature-box-04 {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.feature-box-04:hover {
  -webkit-box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15);
}

.feature-box-04 .fetaure-image {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.feature-box-04 .fetaure-image img {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.feature-box-04 .fetaure-image:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.feature-box-04 .fetaure-image:after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 -130px 70px -70px #000 inset;
  box-shadow: 0 -130px 70px -70px #000 inset;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.feature-box-04 .package-detail {
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px 18px;
  position: absolute;
  z-index: 9;
}

.feature-box-04 .title {
  color: #fff;
  margin-right: 5px;
}

.feature-box-04 .price {
  width: 60%;
  float: left;
}

.feature-box-04 .people-duration {
  float: right;
  margin-left: 5px;
}

.feature-box-04 .package-detail p {
  margin: 0 0 0 10px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

.feature-box-04 .package-detail p .theme-color {
  margin-right: 5px;
}

.feature-box-04 .rating li {
  float: left;
  margin-left: 3px;
}

@media screen and (max-width: 767px) {
  .feature-box-04 .package-detail {
    padding: 20px 10px;
  }

  .feature-box-04 .price {
    width: 55%;
  }
}

.feature-box-05 {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.feature-box-05 .fetaure-image {
  position: relative;
  overflow: hidden;
}

.feature-box-05 .fetaure-image img {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.feature-box-05 .fetaure-image:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.feature-box-05 .fetaure-image:after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 -130px 70px -70px rgba(0, 0, 0, 0.65) inset;
  box-shadow: 0 -130px 70px -70px rgba(0, 0, 0, 0.65) inset;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.feature-box-05 .package-detail {
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px 18px;
  position: absolute;
  z-index: 9;
}

.feature-box-06 {
  text-align: left;
  transition: all 0.5s ease 0s;
}

.feature-box-06 i {
  color: #39b35d;
}

.feature-box-06 p {
  color: #6f6f6f;
}

.feature-box-07 {
  width: 100%;
  float: left;
}

.feature-box-07 .img-box {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.feature-box-07 .feature-textbox {
  position: relative;
  width: 90%;
  z-index: 2;
  top: -50px;
  left: 0;
  transition: all 0.3s ease-out 0s;
  right: 0;
  margin: 0 auto;
}

.feature-box-07 .feature-textbox:after {
  border-bottom: 5px solid transparent;
  width: 0px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease-out 0s;
}

.feature-box-07:hover .feature-textbox:after {
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  content: "";
  width: 100%;
}

.feature-box-07 .feature-textbox:before {
  border-top: 5px solid #39b35d;
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.feature-box-07:hover .feature-textbox:before {
  width: 0;
  transition: all 0.5s ease-out 0s;
}

.feature-box-07 .feature-textbox a {
  color: #39b35d;
}

.feature-box-07:hover .feature-textbox {
  background-color: #39b35d;
}

.feature-box-07:hover .feature-textbox .title,
.feature-box-07:hover .feature-textbox .feature-desc,
.feature-box-07:hover .feature-textbox a.read-more {
  color: #fff;
}

.feature-box-07 .img-box img {
  transition: all 0.3s ease-out 0s;
}

.feature-box-07:hover .img-box img {
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 991px) {
  .feature-box-07 .feature-textbox {
    width: 95%;
    top: -30px;
  }
}

.feature-box-08 {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  position: relative;
}

.feature-box-08 .show-details {
  opacity: 0;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.feature-box-08 .show-details > img {
  width: 100%;
  max-width: none;
}

.feature-box-08:hover .show-details {
  opacity: 1;
}

.feature-box-08 .feature-box-detail {
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
}

.feature-box-08 .feature-box-detail .height-100 {
  overflow: auto;
}

.feature-box-08 .feature-box-detail .list-style-5 li {
  margin-bottom: 8px;
}

.feature-box9 {
  position: relative;
}

.feature-box9:after {
  background: #39b35d;
  position: absolute;
  content: "";
  right: -2px;
  top: 15px;
  width: 2px;
  height: 25px;
  transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.feature-box9 i {
  transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.feature-box9:hover i {
  color: #39b35d;
}

.feature-box9:hover:after {
  top: 0;
  height: 50px;
}

.feature-flex {
  position: relative;
  margin: 30px 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.feature-flex-square {
  position: relative;
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.feature-flex-square-icon {
  float: left;
  width: 50px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.feature-flex-square-icon i {
  color: #39b35d;
  border-radius: 10px;
  font-size: 32px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.feature-flex-square-content {
  float: left;
  width: 75%;
  padding-left: 10px;
}

.feature-flex-square-content h4 {
  color: #232323;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: normal;
}

.feature-flex-square-content h4 a {
  color: #232323;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: normal;
}

.feature-flex-square-content p {
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 15px;
}

.feature-flex-square-content-button {
  display: inline-block;
  color: #232323;
  font-size: 14px;
  font-weight: 500;
}

.feature-flex-square-content-button:after {
  content: "\f0da";
  font-weight: 700;
  font-size: 14px;
  font-family: Font awesome\5 Free;
  color: #232323;
  margin-left: 7px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.feature-flex-square-content-button:hover {
  color: #39b35d;
}

.feature-flex-square-content-button:hover:after {
  color: #39b35d;
}

@media screen and (max-width: 991px) {
  .feature-flex-square-icon {
    text-align: center;
  }

  .feature-flex-square-icon i {
    font-size: 30px;
  }

  .feature-flex-square-content h4 {
    font-size: 16px;
  }

  .feature-flex-square-content h4 a {
    font-size: 16px;
  }

  .feature-flex-square-content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .feature-flex-square-icon {
    text-align: left;
    width: 40px;
  }
}

.case-block {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  height: 100%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  position: relative;
}

.case-block:hover {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.3);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.3);
}

.case-block img {
  width: 100%;
}

.case-block-inner {
  padding: 20px 25px 20px 0;
}

.case-block h4 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 500;
}

.case-block h4:after {
  content: "";
  display: block;
  width: 80px;
  height: 2px;
  background: #39b35d;
  margin-top: 10px;
  margin-bottom: 15px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.case-block p {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 12px;
}

.case-block-inner a {
  font-size: 15px;
  font-weight: 500;
  color: #39b35d;
}

.case-block-inner a:after {
  content: "\f0da";
  font-size: 15px;
  font-weight: 700;
  font-family: Font awesome\5 Free;
  color: #39b35d;
  margin-left: 10px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  vertical-align: middle;
}

.case-block-inner a:hover {
  color: #232323;
}

.case-block-inner a:hover:after {
  color: #232323;
}

@media screen and (max-width: 767px) {
  .case-block-inner {
    padding: 25px;
  }
}

.partner-box {
  border: 1px solid #ececec;
}

.partner-box img {
  width: 100%;
}

.partner-text {
  padding: 25px 40px 25px 0;
}

.partner-text span {
  color: #39b35d;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.partner-box:hover .partner-text span {
  color: #999;
}

.partner-text h4 {
  color: #232323;
  font-size: 21px;
  font-weight: 600;
  margin: 5px 0 20px 0;
  line-height: normal;
}

.partner-text h4:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background: #39b35d;
  margin-top: 10px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.partner-box:hover .partner-text h4:after {
  width: 80px;
}

@media screen and (max-width: 767px) {
  .partner-text {
    padding: 25px;
  }
}

.feedback-box {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #ececec;
  padding: 25px;
}

.feedback-box h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .sm-bg {
    min-height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .sm-bg {
    min-height: 320px;
  }
}

.services-blocks {
  margin-top: -90px;
}

@media screen and (max-width: 1199px) {
  .services-blocks {
    margin-top: -70px;
  }
}

@media screen and (max-width: 991px) {
  .services-blocks {
    margin-top: -50px;
  }
}

.service-simple {
  position: relative;
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.service-simple img {
  width: 100%;
  transition: all 0.5s ease 0s;
}

.service-simple img:hover {
  transform: scale(1.1);
}

.service-simple-inner {
  position: relative;
  padding: 25px 25px 20px;
}

.service-simple-inner p {
  max-width: 95%;
}

.service-simple-inner a {
  display: inline-block;
}

.service-simple h4 {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0;
}

.owl-carousel .service-box {
  margin: 0 15px 25px 15px;
}

.service-box {
  background: #fff;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(50, 50, 50, 0.12);
  -moz-box-shadow: 0 10px 20px 0 rgba(50, 50, 50, 0.12);
  box-shadow: 0 10px 20px 0 rgba(50, 50, 50, 0.12);
  margin: 15px 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.service-inner-box {
  padding: 20px;
}

.service-icon-box {
  width: 20%;
  max-width: 50px;
  float: left;
  padding-top: 2px;
}

.service-content-box {
  width: 80%;
  float: left;
}

.service-box .img-holder {
  position: relative;
}

.service-box .img-holder:before {
  background-color: rgba(73, 192, 208, 0.6);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: auto;
  transition: all 0.3s ease 0s;
  width: 100%;
  z-index: 10;
}

.service-box:hover .img-holder:before {
  opacity: 1;
}

.service-box .img-holder img {
  width: 100%;
}

.service-box h3 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 500;
}

.service-box p {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0;
}

.service-box i {
  color: #39b35d;
  font-size: 28px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.service-box:hover i {
  color: #232323;
}

.services-block-two {
  position: relative;
}

.services-block-two .inner-box {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 30px 30px 30px 95px;
  height: 100%;
  position: relative;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.services-block-two p {
  margin-bottom: 0;
}

.services-block-two h3 {
  margin-bottom: 0;
  line-height: normal;
  display: inline-block;
}

.services-block-two h3 a {
  color: #3c3c3c;
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.services-block-two h3 a:hover {
  color: #39b35d;
}

.services-block-two h2 {
  bottom: 0;
  color: #f0f0f0;
  font-size: 36px;
  font-weight: 600;
  right: 10px;
  margin-bottom: 0;
  position: absolute;
}

.services-block-two .icon-box {
  color: #39b35d;
  font-size: 36px;
  left: 35px;
  position: absolute;
  top: 35px;
}

.services-block-two.dark .inner-box {
  background: #000;
  border: 1px solid #000;
}

.services-block-two.dark .inner-box:hover {
  background: #000;
}

.services-block-two.dark .inner-box:hover h5 a,
.services-block-two.dark .inner-box:hover p,
.services-block-two.dark .inner-box:hover .icon-box {
  color: #fff;
}

.services-block-two.dark h3 a {
  color: #6f6f6f;
}

.services-block-two.dark h2 {
  color: rgba(255, 255, 255, 0.1);
}

.services-block-two.dark .inner-box:hover h2 {
  color: rgba(255, 255, 255, 0.04);
}

.services-block-two.dark .icon-box {
  color: #6f6f6f;
}

@media screen and (max-width: 991px) {
  .services-block-two h2 {
    font-size: 36px;
  }

  .services-block-two h3 a {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .services-block-two .icon-box {
    top: 25px;
    left: 21px;
    font-size: 28px;
  }

  .services-block-two .inner-box {
    padding: 20px 20px 20px 75px;
  }
}

.services-block-three > a {
  display: block;
  border: 3px solid #d5d5d5;
  border-radius: 0;
  text-align: center;
  background: #fff;
  padding: 20px;
  position: relative;
}

.services-block-three > a:before {
  display: block;
  content: "";
  width: 9%;
  height: 17%;
  position: absolute;
  bottom: -3px;
  right: -3px;
  border-bottom: 3px solid #39b35d;
  border-right: 3px solid #39b35d;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.services-block-three > a:after {
  display: block;
  content: "";
  width: 9%;
  height: 17%;
  position: absolute;
  top: -3px;
  left: -3px;
  border-top: 3px solid #39b35d;
  border-left: 3px solid #39b35d;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.services-block-three > a:hover {
  opacity: 1;
  border-color: #d5d5d5;
}

.services-block-three > a:hover:before,
.services-block-three > a:hover:after {
  width: 95%;
  height: 90%;
}

.services-block-three i {
  font-size: 32px;
}

.services-block-three p {
  margin-bottom: 0;
}

.services-block-three:hover p {
  color: #232323;
}

.services-block-three h4 {
  color: #6f6f6f;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.services-block-three:hover h4 {
  color: #232323;
}

.service-grids .service-block {
  overflow: hidden;
  position: relative;
}

.service-grids .img-holder {
  position: relative;
}

.service-grids .service-block:hover .img-holder:before {
  opacity: 1;
}

.service-grids .service-block .details {
  background-color: #fff;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.07);
  padding: 45px 45px 50px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease 0s;
  z-index: 20;
}

.service-grids .service-block .number {
  background-color: #39b35d;
  border-radius: 75px;
  color: #fff;
  height: 75px;
  font-size: 30px;
  left: 50%;
  line-height: 75px;
  position: absolute;
  top: -37.5px;
  transform: translateX(-50%);
  width: 75px;
}

.service-grids .service-block .details h4 {
  color: #39b35d;
  font-size: 20px;
  margin: 10px 0 15px 0;
  line-height: normal;
}

.service-grids .service-block .details .read-more {
  color: #39b35d;
  font-weight: 600;
}

@media screen and (min-width: 992px) {
  .service-grids .service-block {
    height: 335px;
  }

  .service-grids .img-holder:before {
    background-color: rgba(73, 192, 208, 0.6);
    bottom: 20px;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: auto;
    transition: all 0.3s ease 0s;
    width: 100%;
    z-index: 10;
  }

  .service-grids .service-block .details {
    height: 195px;
    position: absolute;
    top: 168px;
  }

  .service-grids .service-block:hover .details {
    height: 230px;
    top: 105px;
  }
}

@media screen and (min-width: 1200px) {
  .service-grids .service-block {
    height: 370px;
  }

  .service-grids .service-block .details {
    height: 195px;
    position: absolute;
    top: 205px;
  }

  .service-grids .service-block:hover .details {
    height: 230px;
    top: 170px;
  }
}

@media screen and (max-width: 991px) {
  .service-grids .service-block .details {
    padding: 45px 25px 35px;
  }
}

@media screen and (max-width: 767px) {
  .service-grids .service-block .number {
    height: 65px;
    line-height: 65px;
    top: -32.5px;
    width: 65px;
    border-radius: 65px;
  }
}

.service-block5 {
  position: relative;
}

.service-block5 > a {
  display: inline-block;
  width: 100%;
}

.service-block5:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: " ";
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.service-block5:hover:before {
  content: none;
}

.service-block5:after {
  background: #39b35d none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.service-block5:hover:after {
  opacity: 0.8;
}

.service-block5 a img {
  max-width: 100%;
}

.service-block5 .service-desc {
  bottom: 25px;
  position: absolute;
  padding: 0 20px;
  z-index: 9;
}

.service-block5 .service-desc h5 {
  color: #39b35d;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 0;
  line-height: normal;
}

.service-block5:hover .service-desc h5 {
  color: #fff;
}

.service-block5 .service-desc h4 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 24px;
}

.service-block5 .service-desc h4 a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 24px;
}

@media screen and (max-width: 991px) {
  .service-block5 .service-desc {
    bottom: 30px;
  }

  .service-block5 .service-desc h4 {
    font-size: 16px;
  }

  .service-block5 .service-desc h4 a {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .service-block5 .service-desc {
    bottom: 20px;
  }

  .service-block5 .service-desc h4 {
    font-size: 15px;
  }

  .service-block5 .service-desc h4 a {
    font-size: 15px;
  }

  .service-block5 .service-desc h5 {
    font-size: 12px;
  }
}

.services-single-menu li {
  border-bottom: 1px solid #ececec;
}

.services-single-menu li:last-child {
  border-bottom: none;
}

.services-single-menu li.active a {
  border-left-color: #39b35d;
  color: #39b35d;
}

.services-single-menu li.active a:after {
  content: none;
}

.services-single-menu li a {
  display: block;
  border-left: 4px solid transparent;
  font-size: 15px;
  font-weight: 600;
  padding: 14px 10px 14px 20px;
}

.services-single-menu li a:after {
  content: "";
  background: #39b35d;
  display: inline-block;
  height: 1px;
  width: 0;
  vertical-align: middle;
  margin-left: 8px;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

.services-single-menu li a:hover:after {
  width: 30px;
}

.callback-box {
  background: #232323;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px 15px 25px 15px;
  position: relative;
}

.services-single-right .special {
  padding: 15px;
  margin: 30px 0;
  border-left: 2px solid #111;
  background: #f7f7f7;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .services-single-menu li a {
    font-size: 14px;
    padding: 12px 10px 12px 16px;
  }
}

.downloads li {
  border: 1px solid #39b35d;
  border-radius: 5px;
}

.downloads i {
  background: #39b35d;
  padding: 10px 12px;
  color: #fff;
  vertical-align: top;
}

.downloads .label {
  margin-top: 10px;
  display: inline-block;
  margin-left: 10px;
}

.process-step-xs {
  position: relative;
  margin: 15px 0;
  padding: 0;
}

.process-step-xs .process-step-icon {
  text-align: center;
}

.process-step-xs .process-step-icon i {
  color: #39b35d;
  font-size: 47px;
}

.process-step-xs h3 {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #6f6f6f;
  margin: 10px 0;
  text-align: center;
}

.process-steps-xs li {
  position: relative;
  float: left;
  list-style-type: none;
  display: inline-block;
}

.process-steps-xs li:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 8px solid transparent;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  border-bottom: 8px solid transparent;
  border-radius: 2px;
  position: absolute;
  right: -7px;
  top: 40px;
  text-align: center;
  border-left: 8px solid rgba(0, 0, 0, 0.1);
}

.process-steps-xs li:last-child:after {
  display: none;
}

@media screen and (max-width: 991px) {
  .process-step-xs .process-step-icon i {
    font-size: 44px;
  }
}

@media screen and (max-width: 767px) {
  .process-steps-xs li:nth-child(2):after {
    display: none;
  }

  .process-step-xs .process-step-icon i {
    font-size: 40px;
  }

  .process-step-xs h3 {
    font-size: 13px;
    margin: 5px 0;
  }

  .process-steps-xs li:after {
    right: 0;
  }
}

.section-clients {
  padding: 50px 0;
}

.clients img {
  opacity: 0.6;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.clients img:hover {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .section-clients {
    padding: 30px 0;
  }

  .section-clients .item {
    text-align: center;
    width: 100%;
  }

  .section-clients .owl-carousel .owl-item img {
    max-width: 75%;
    display: inline-block;
  }
}

.section-clients2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.section-clients2 .client-single {
  width: 33.333%;
  min-height: 96px;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  overflow: hidden;
}

.section-clients2 .client-single .client-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  opacity: 0.8;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section-clients2 .client-single .client-img img {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.section-clients2 .client-single .client-img:hover {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .section-clients2 .client-single {
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .section-clients2 .client-single {
    width: 100%;
  }
}

.section-clients3 .client-single {
  margin-bottom: 30px;
}

.section-clients3 .client-single:last-child,
.section-clients3 .client-single p {
  margin-bottom: 0;
}

.section-clients3 .client-single a {
  display: block;
}

.section-clients3 .client-single a:after {
  content: "";
  display: block;
  clear: both;
}

.section-clients3 .client-img {
  position: relative;
  float: left;
  padding: 10px;
  min-height: 102px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 225px;
  margin: 0 30px 0 0;
  border: 1px solid #ddd;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-clients3 .client-img:before {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 3px solid transparent;
  z-index: 100;
}

.section-clients3 .client-desc {
  overflow: hidden;
}

@media screen and (max-width: 575px) {
  .section-clients3 .client-img {
    float: none;
    margin-bottom: 15px;
  }
}

.counter-box h3,
.counter-box h4 {
  display: inline-block;
  font-weight: 600;
}

.social-icons a:hover {
  opacity: 1;
}

.counter-box {
  position: relative;
  text-align: center;
}

.counter-box h3:after {
  content: "+";
}

.counter-box h4 {
  font-size: 35px;
  line-height: normal;
  margin: 0;
}

.counter-box h4:after {
  content: "+";
}

.counter-box h3 {
  font-size: 45px;
}

@media screen and (max-width: 767px) {
  .counter-box p:before {
    margin: 0;
    position: absolute;
    top: 45px;
  }

  .counter-box p:after {
    margin: 0;
    left: 14px;
    right: 0;
    top: 45px;
  }

  .counter-box.black p:after {
    margin: 0;
    left: 14px;
    right: 0;
    top: 45px;
  }

  .counter-box h4 {
    font-size: 26px;
  }
}

.counter-box-style2 {
  position: relative;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
}

.counter-box-style2 h3:after,
.counter-box-style2 h4:after {
  content: "+";
}

.callto-action-imgbox {
  display: block;
  margin-right: 50px;
  padding-left: 40px;
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

.callto-action-imgbox:before {
  border: 2px solid #39b35d;
  bottom: 40px;
  content: "";
  left: 0;
  position: absolute;
  right: 40px;
  top: 0;
  z-index: -1;
}

.callto-action-imgbox img {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .callto-action-imgbox {
    margin-right: 0;
    margin-bottom: 30px;
    padding: 20px 20px 0 20px;
  }
}

.story-video {
  height: 100%;
}

.story-video-height {
  min-height: 400px;
  height: 100%;
}

.video_btn {
  position: relative;
  height: 80px;
  width: 80px;
  background: #39b35d;
  text-align: center;
  display: inline-block;
  line-height: 85px;
  color: #fff;
  border-radius: 50%;
  transition-duration: 0s;
  -ms-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
}

.video_btn:hover i,
.video_btn:focus i {
  color: #fff;
}

.video_btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80px;
  width: 80px;
  border: 2px solid #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.3;
  animation: pulse-border 1500ms ease-out infinite;
}

.video_btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 95px;
  width: 95px;
  border: 2px solid #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.3;
  animation: pulse-border 1500ms ease-out infinite;
}

.video_btn-style2:after,
.video_btn-style2:before {
  border-color: rgba(0, 0, 0, 0.1);
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.video_btn.border-grey:after,
.video_btn.border-grey:before {
  border: 2px solid #d8d8d8;
}

.video_btn.small {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.video_btn.small:after {
  height: 50px;
  width: 50px;
}

.video_btn.small:before {
  height: 65px;
  width: 65px;
}

@media screen and (max-width: 1199px) {
  .story-video-height {
    min-height: 350px;
  }

  .video_btn {
    height: 75px;
    width: 75px;
    line-height: 80px;
  }

  .video_btn:after {
    height: 75px;
    width: 75px;
  }

  .video_btn:before {
    height: 90px;
    width: 90px;
  }
}

@media screen and (max-width: 991px) {
  .story-video {
    min-height: 325px;
  }

  .story-video-height {
    min-height: 300px;
  }

  .video_btn {
    height: 70px;
    width: 70px;
    line-height: 70px;
  }

  .video_btn:after {
    height: 70px;
    width: 70px;
  }

  .video_btn:before {
    height: 85px;
    width: 85px;
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    }

    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    }
  }
}

@media screen and (max-width: 767px) {
  .story-video {
    min-height: 200px;
  }

  .story-video-height {
    min-height: 250px;
  }

  .video_btn {
    height: 60px;
    width: 60px;
    line-height: 62px;
  }

  .video_btn:after {
    height: 60px;
    width: 60px;
  }

  .video_btn:before {
    height: 75px;
    width: 75px;
  }
}

.about-area {
  padding: 0;
}

.about-text {
  display: block;
  padding-bottom: 90px;
  padding-top: 90px;
}

.about-text .sec-title {
  padding-bottom: 34px;
}

.about-text .inner-content h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin: 0 0 14px;
  color: #fff;
}

.about-text .inner-content h2 span {
  font-weight: 700;
}

.about-text .inner-content p {
  color: #fff;
}

.about-text .inner-content .bottom {
  display: block;
  overflow: hidden;
  margin-top: 25px;
}

.about-text .inner-content .bottom p {
  margin: 0;
  font-weight: 500;
}

.about-text .inner-content .bottom .signature {
  display: block;
  margin-top: 22px;
}

.about-right-box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 90px 40px;
  z-index: 1;
  height: 100%;
}

.about-right-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #39b35d;
  opacity: 0.92;
  content: "";
  z-index: -1;
}

.about-right-box ul {
  margin-bottom: 0;
}

.about-right-box ul li {
  display: block;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 29px;
  margin-bottom: 30px;
}

.about-right-box ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.about-right-box ul li .left-content {
  display: block;
  width: 70%;
}

.about-right-box ul li .left-content .icon {
  width: 55px;
  display: table-cell;
  vertical-align: top;
  color: rgba(255, 255, 255, 0.5);
}

.about-right-box ul li .left-content .icon span:before {
  font-size: 55px;
  line-height: 55px;
}

.about-right-box ul li .left-content .title {
  padding-left: 30px;
  display: table-cell;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.5);
}

.about-right-box ul li .left-content .title h3 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0 0 1px;
}

.about-right-box ul li .left-content .title span {
  color: rgba(255, 255, 255, 0.75);
}

.about-right-box ul li .right-content .title {
  display: block;
  margin-top: 3px;
}

.about-right-box ul li .right-content .title h4 {
  color: #ffffff;
  font-size: 3.4em;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

@media screen and (max-width: 1199px) {
  .about-text,
  .about-right-box {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 991px) {
  .about-area {
    padding-bottom: 50px;
  }

  .about-text,
  .about-right-box {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .about-right-box {
    padding: 40px 20px;
  }

  .about-right-box ul li {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .about-right-box ul li .left-content .title {
    padding-left: 10px;
  }

  .about-right-box ul li .left-content .icon span:before {
    font-size: 36px;
    line-height: normal;
  }

  .about-right-box ul li .right-content .title h4 {
    font-size: 26px;
  }
}

.about-ceo {
  position: relative;
}

.about-ceo .about-project {
  position: absolute;
  right: 20px;
  bottom: 19px;
  background: #f7f7f7;
  z-index: 9;
}

.about-ceo .about-project h3 {
  margin-bottom: 0;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  color: #39b35d;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.about-ceo .about-project p {
  display: block;
  font-size: 18px;
  color: #232323;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.about-ceo .box-area {
  position: relative;
  background: #fff;
  padding: 25px;
  text-align: center;
  border: 8px solid #39b35d;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before {
  content: " ";
  display: table;
}

.timeline > li:after {
  content: " ";
  display: table;
  clear: both;
}

.timeline > li:before {
  content: " ";
  display: table;
}

.timeline > li:after {
  content: " ";
  display: table;
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #999999;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.progress-text {
  font-size: 13px;
  font-weight: 500;
  color: #232323;
  margin-bottom: 5px;
}

.custom-progress {
  height: 4px;
  border-radius: 50px;
  box-shadow: none;
  margin-bottom: 15px;
}

.custom-bar {
  height: 100%;
  background-color: #39b35d;
  box-shadow: none;
}

.custom-bar-dark {
  height: 100%;
  background-color: #232323;
  box-shadow: none;
}

.progress-medium {
  height: 8px;
  border-radius: 50px;
  box-shadow: none;
}

.progress-bold {
  height: 12px;
  border-radius: 50px;
  box-shadow: none;
}

.testmonial-single {
  text-align: center;
}

#testmonials-carousel .testmonial-single img {
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
}

.testmonial-single p {
  text-align: center;
  font-style: italic;
  font-weight: 600;
}

.testmonial-single p:before {
  margin: auto auto 15px;
  content: "\f10d";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  color: #39b35d;
  font-size: 23px;
  display: block;
}

.testmonial-single h4 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 5px 0;
  line-height: normal;
}

.testmonial-single h6 {
  color: #aaa;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  line-height: normal;
}

.testmonial-box {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 15px 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.testmonial-box img {
  border: 2px solid #ececec;
  border-radius: 50%;
  padding: 2px;
}

.owl-carousel .testmonial-box img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-box h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 0;
  line-height: normal;
}

.testmonial-box span {
  font-size: 13px;
}

.testmonial-box p {
  font-size: 15px;
  color: #999;
  line-height: 1.7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.testmonial-style2 {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 0 0 30px 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.testmonial-style2 img {
  border: 2px solid #ececec;
  border-radius: 50%;
  padding: 2px;
}

.owl-carousel .testmonial-style2 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-style2 h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 0;
  line-height: normal;
}

.testmonial-style2 span {
  font-size: 13px;
}

.testmonial-style2 p {
  font-size: 15px;
  color: #999;
  line-height: 1.7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.testimonial-style3 .testimonial-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-style3 .testimonial-review p {
  margin-bottom: 0;
}

.testimonial-style3 .testimonial-left-col {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 177px;
  flex: 1 0 177px;
  margin-right: 100px;
  text-align: center;
}

.testimonial-style3 .testimonial-pic {
  position: relative;
  margin: 0 0 25px;
}

.testimonial-style3 .testimonial-pic:before {
  content: "\f10d";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  display: block;
  position: absolute;
  top: 50%;
  right: -62px;
  margin-top: -8px;
  font-size: 21px;
  color: #39b35d;
}

.testimonial-style3 .testimonial-separator:before {
  content: "";
  display: block;
  width: 1px;
  height: 60px;
  position: absolute;
  right: -53px;
  background-color: #6f6f6f;
}

.testimonial-style3 .testimonial-separator:after {
  content: "";
  display: block;
  width: 1px;
  height: 60px;
  position: absolute;
  right: -53px;
  background-color: #6f6f6f;
  bottom: 0;
}

.testimonial-style3 .client-info h6 {
  margin-bottom: 0;
  font-size: 15px;
  color: #fff;
}

.testimonial-style3 .client-info span {
  color: #d6d5d5;
}

.testimonial-style3 .testimonial-review {
  padding-bottom: 70px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 300;
}

.testimonial-style3 .custom-dot {
  display: inline-block;
  z-index: 1;
  margin-top: 30px;
}

.testimonial-style3 .custom-dot span {
  width: 12px;
  height: 12px;
  margin: 0 7px;
  background: none;
  cursor: pointer;
  display: block;
  border: 1px solid #fff;
  -webkit-backface-visibility: visible;
  border-radius: 0;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
}

.testimonial-style3 .custom-dot span:hover {
  background: #fff;
  -webkit-backface-visibility: visible;
  border-radius: 0;
}

@media screen and (max-width: 991px) {
  .testimonial-style3 .testimonial-review {
    padding-bottom: 65px;
  }
}

@media screen and (max-width: 767px) {
  .testimonial-style3 .client-info h6 {
    font-style: 14px;
  }

  .testimonial-style3 .custom-dot {
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .testimonial-style3 .testimonial-review {
    padding-bottom: 0;
  }

  .testimonial-style3 .testimonial-left-col {
    margin-bottom: 20px;
    text-align: left;
  }

  .testimonial-style3 .testimonial-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .testimonial-style3 .testimonial-separator,
  .testimonial-style3 .testimonial-pic:before {
    display: none;
  }
}

.testimonial-style4 {
  border: 2px solid #ededed;
  padding: 25px;
  position: relative;
}

.testimonial-style4:after {
  background: #39b35d;
  position: absolute;
  content: "";
  right: -2px;
  top: 15px;
  width: 2px;
  height: 25px;
  transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.testimonial-style4:hover:after {
  top: 0;
  height: 50px;
}

.testimonial-style4 .quote {
  font-size: 80px;
  line-height: 30px;
  margin-top: 40px;
}

.testimonial-style4 img {
  border-radius: 50%;
  max-width: 120px;
}

.testimonial-style4 .name {
  position: relative;
}

@media screen and (max-width: 767px) {
  .testimonial-style4 .quote {
    font-size: 60px;
  }
}

.testmonials-style5 {
  position: relative;
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 30px 0;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.testmonials-style5:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  height: 5px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
}

.testmonials-style5 img {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 2px;
}

.owl-carousel .testmonials-style5 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonials-style5 h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
}

.testmonials-style5 span {
  font-size: 13px;
  color: #39b35d;
}

.testmonials-style5 span:before {
  background: rgba(0, 0, 0, 0.2);
  height: 1px;
  width: 20px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.testmonials-style5 p {
  margin-top: 10px;
}

.testmonials-style5 .data {
  position: relative;
}

.testmonials-style5 .data i {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 991px) {
  .testmonials-style5 {
    margin-bottom: 20px;
  }

  .testmonials-style5 p {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .testmonials-style5 {
    margin-bottom: 15px;
    padding: 25px 15px;
  }

  .testmonials-style5 h5 {
    font-size: 15px;
  }
}

.testimonial-style6 .testmonial-single h4 {
  font-size: 16px;
}

.testimonial-style6 .testmonial-single h6 {
  font-size: 11px;
  margin-bottom: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #39b35d;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  background: #fff !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
  box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
}

.owl-nav i,
.owl-nav span {
  color: #232323;
  font-size: 18px;
  padding-top: 4px;
}

.owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}

.testmonial-single {
  text-align: center;
}

#testmonials-carousel .testmonial-single img {
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
}

.testmonial-single p {
  text-align: center;
  font-style: italic;
  font-weight: 600;
}

.testmonial-single p:before {
  margin: auto auto 15px;
  content: "\f10d";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  color: #39b35d;
  font-size: 23px;
  display: block;
}

.testmonial-single h4 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 5px 0;
  line-height: normal;
}

.testmonial-single h6 {
  color: #aaa;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  line-height: normal;
}

.testmonial-box {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 15px 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.testmonial-box img {
  border: 2px solid #ececec;
  border-radius: 50%;
  padding: 2px;
}

.owl-carousel .testmonial-box img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-box h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 0;
  line-height: normal;
}

.testmonial-box span {
  font-size: 13px;
}

.testmonial-box p {
  font-size: 15px;
  color: #999;
  line-height: 1.7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.testmonial-style2 {
  position: relative;
  background: #fff;
  border-radius: 5px;
  padding: 25px;
  border: 1px solid #ececec;
  margin: 0 0 30px 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.testmonial-style2 img {
  border: 2px solid #ececec;
  border-radius: 50%;
  padding: 2px;
}

.owl-carousel .testmonial-style2 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonial-style2 h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 0;
  line-height: normal;
}

.testmonial-style2 span {
  font-size: 13px;
}

.testmonial-style2 p {
  font-size: 15px;
  color: #999;
  line-height: 1.7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.testimonial-style3 .testimonial-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-style3 .testimonial-review p {
  margin-bottom: 0;
}

.testimonial-style3 .testimonial-left-col {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 177px;
  flex: 1 0 177px;
  margin-right: 100px;
  text-align: center;
}

.testimonial-style3 .testimonial-pic {
  position: relative;
  margin: 0 0 25px;
}

.testimonial-style3 .testimonial-pic:before {
  content: "\f10d";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  display: block;
  position: absolute;
  top: 50%;
  right: -62px;
  margin-top: -8px;
  font-size: 21px;
  color: #39b35d;
}

.testimonial-style3 .testimonial-separator:before {
  content: "";
  display: block;
  width: 1px;
  height: 60px;
  position: absolute;
  right: -53px;
  background-color: #6f6f6f;
}

.testimonial-style3 .testimonial-separator:after {
  content: "";
  display: block;
  width: 1px;
  height: 60px;
  position: absolute;
  right: -53px;
  background-color: #6f6f6f;
  bottom: 0;
}

.testimonial-style3 .client-info h6 {
  margin-bottom: 0;
  font-size: 15px;
  color: #fff;
}

.testimonial-style3 .client-info span {
  color: #d6d5d5;
}

.testimonial-style3 .testimonial-review {
  padding-bottom: 70px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 300;
}

.testimonial-style3 .custom-dot {
  display: inline-block;
  z-index: 1;
  margin-top: 30px;
}

.testimonial-style3 .custom-dot span {
  width: 12px;
  height: 12px;
  margin: 0 7px;
  background: none;
  cursor: pointer;
  display: block;
  border: 1px solid #fff;
  -webkit-backface-visibility: visible;
  border-radius: 0;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
}

.testimonial-style3 .custom-dot span:hover {
  background: #fff;
  -webkit-backface-visibility: visible;
  border-radius: 0;
}

@media screen and (max-width: 991px) {
  .testimonial-style3 .testimonial-review {
    padding-bottom: 65px;
  }
}

@media screen and (max-width: 767px) {
  .testimonial-style3 .client-info h6 {
    font-style: 14px;
  }

  .testimonial-style3 .custom-dot {
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .testimonial-style3 .testimonial-review {
    padding-bottom: 0;
  }

  .testimonial-style3 .testimonial-left-col {
    margin-bottom: 20px;
    text-align: left;
  }

  .testimonial-style3 .testimonial-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .testimonial-style3 .testimonial-separator,
  .testimonial-style3 .testimonial-pic:before {
    display: none;
  }
}

.testimonial-style4 {
  border: 2px solid #ededed;
  padding: 25px;
  position: relative;
}

.testimonial-style4:after {
  background: #39b35d;
  position: absolute;
  content: "";
  right: -2px;
  top: 15px;
  width: 2px;
  height: 25px;
  transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

.testimonial-style4:hover:after {
  top: 0;
  height: 50px;
}

.testimonial-style4 .quote {
  font-size: 80px;
  line-height: 30px;
  margin-top: 40px;
}

.testimonial-style4 img {
  border-radius: 50%;
  max-width: 120px;
}

.testimonial-style4 .name {
  position: relative;
}

@media screen and (max-width: 767px) {
  .testimonial-style4 .quote {
    font-size: 60px;
  }
}

.testmonials-style5 {
  position: relative;
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 30px 0;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.testmonials-style5:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  height: 5px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
}

.testmonials-style5 img {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 2px;
}

.owl-carousel .testmonials-style5 img {
  width: auto;
  max-height: 120px;
  display: inline-block;
}

.testmonials-style5 h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
}

.testmonials-style5 span {
  font-size: 13px;
  color: #39b35d;
}

.testmonials-style5 span:before {
  background: rgba(0, 0, 0, 0.2);
  height: 1px;
  width: 20px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.testmonials-style5 p {
  margin-top: 10px;
}

.testmonials-style5 .data {
  position: relative;
}

.testmonials-style5 .data i {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 991px) {
  .testmonials-style5 {
    margin-bottom: 20px;
  }

  .testmonials-style5 p {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .testmonials-style5 {
    margin-bottom: 15px;
    padding: 25px 15px;
  }

  .testmonials-style5 h5 {
    font-size: 15px;
  }
}

.testimonial-style6 .testmonial-single h4 {
  font-size: 16px;
  margin-top: 10px;
}

.testimonial-style6 .testmonial-single h6 {
  font-size: 11px;
  margin-bottom: 0;
}

.about-carousel {
  position: relative;
}

.about-carousel:after {
  content: "";
  position: absolute;
  top: 70px;
  left: -70px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 30px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 991px) {
  .about-carousel {
    margin-bottom: 50px;
  }

  .about-carousel:after {
    top: 50px;
    left: -50px;
    border: 20px solid rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 767px) {
  .about-carousel {
    margin: 0;
  }

  .about-carousel:after {
    display: none;
  }
}

.team-member {
  border: 1px solid #ececec;
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  background: #fff;
  overflow: hidden;
}

.team-member:hover {
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.28);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.team-member-img {
  width: 100%;
  height: auto;
}

.team-member-img img {
  width: 100%;
}

.team-member-text {
  text-align: center;
  padding: 20px;
}

.team-member-text h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3px;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.team-member:hover .team-member-text h4 {
  color: #39b35d;
}

.team-member-text h4 a {
  color: #232323;
}

.team-member-text span {
  color: #39b35d;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2px;
}

.team-member-text p {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
  margin: 20px auto 0;
  max-width: 90%;
}

.team-member-text ul {
  margin-top: 15px;
  padding: 8px 0;
}

.team-member-text ul li a i {
  font-size: 17px;
  line-height: 34px;
}

@media screen and (max-width: 991px) {
  .team-member-text {
    padding: 20px 10px;
  }
}

.team-single-img {
  width: 100%;
}

.team-single-img img {
  border-radius: 5px;
}

.team-single-text {
  padding: 5px 50px 15px 15px;
}

.team-single-text span {
  color: #39b35d;
  font-size: 14px;
  font-weight: 500;
}

.team-single-text h4 {
  color: #232323;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
}

.team-single-info {
  padding: 0;
}

.team-single-info li strong {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.team-single-text li span {
  color: #777;
  font-size: 15px;
  font-weight: 400;
}

.team-single-icons ul li {
  display: inline-block;
  border: 1px solid #39b35d;
  border-radius: 50%;
  color: #39b35d;
  margin-right: 8px;
  margin-bottom: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.team-single-icons ul li a {
  color: #39b35d;
  display: block;
  font-size: 14px;
  height: 25px;
  line-height: 26px;
  text-align: center;
  width: 25px;
}

.team-single-icons ul li:hover {
  background: #39b35d;
  border-color: #39b35d;
}

.team-single-icons ul li:hover a {
  color: #fff;
}

.team-style1 .team-member-img {
  overflow: hidden;
  border-radius: 5px;
}

.team-style1 .team-member-img img {
  transition: all 0.5s ease 0s;
  width: 100%;
}

.team-style1 .team-member-img:hover img {
  transform: scale(1.1);
}

.team-style2 .team-member-img img {
  transition: all 0.3s ease 0s;
}

.team-style2 .team-member-img:hover img {
  filter: grayscale(100%);
}

.team-style2 .team-description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  opacity: 0;
  transition: all 200ms ease-in-out;
  padding: 15px;
  z-index: 9;
}

.team-style2 .social-links {
  transform: translateY(15px);
  transition: transform 300ms ease-in-out, opacity 150ms ease-in;
  opacity: 0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.team-style2 .social-links a:hover {
  opacity: 0.65;
}

.team-style2 .team-description-wrapper {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.team-style2 .team-description-content {
  display: table-cell;
  vertical-align: middle;
}

.team-style2 .team-member-img {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-style2 .bg-cover {
  background-color: rgba(255, 66, 41, 0);
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  transform: scale(2);
  transition: all 200ms ease-in-out;
  display: none;
  z-index: 1;
}

.team-style2 .team-member-img > img {
  transition: all 0.2s ease 0s;
}

.team-style2:hover .bg-cover {
  background-color: rgba(0, 0, 0, 0.75);
  display: block;
}

.team-style2:hover .social-links {
  transition-delay: 100ms, 100ms;
  opacity: 1;
  transform: translateY(0);
}

.team-style2:hover .team-member-img > img {
  filter: blur(4px) grayscale(4%);
}

.team-style2:hover .team-description {
  opacity: 1;
}

.team-style3 .team-description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  opacity: 0;
  transition: all 200ms ease-in-out;
  padding: 15px;
  z-index: 9;
}

.team-style3 .social-links {
  transform: translateY(15px);
  transition: transform 300ms ease-in-out, opacity 150ms ease-in;
  opacity: 0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.team-style3 .about-me {
  transform: translateY(15px);
  transition: transform 300ms ease-in-out, opacity 150ms ease-in;
  opacity: 0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  position: relative;
  display: inline-block;
  color: #f7f7f7;
}

.team-style3 .social-links:after {
  background: #39b35d;
  bottom: -12px;
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 75px;
}

.team-style3 .social-links a:hover {
  opacity: 0.65;
}

.team-style3 .team-description-wrapper {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.team-style3 .team-description-content {
  display: table-cell;
  vertical-align: middle;
}

.team-style3 .team-member-img {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-style3 .bg-cover {
  background-color: rgba(255, 66, 41, 0);
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  transform: scale(2);
  transition: all 200ms ease-in-out;
  display: none;
  z-index: 1;
}

.team-style3 .team-member-img > img {
  transition: all 0.2s ease 0s;
}

.team-style3:hover .bg-cover {
  background-color: rgba(0, 0, 0, 0.83);
  display: block;
}

.team-style3:hover .social-links {
  transition-delay: 100ms, 100ms;
  opacity: 1;
  transform: translateY(0);
}

.team-style3:hover .about-me {
  transition-delay: 200ms, 200ms;
  opacity: 1;
  transform: translateY(0);
}

.team-style3:hover .team-member-img > img {
  filter: blur(4px) grayscale(4%);
}

.team-style3:hover .team-description {
  opacity: 1;
}

.team-style4 .team-description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  opacity: 0;
  transition: all 200ms ease-in-out;
  padding: 15px;
  z-index: 9;
}

.team-style4 .social-links {
  transform: translateY(15px);
  transition: transform 300ms ease-in-out, opacity 150ms ease-in;
  opacity: 0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.team-style4 .about-me {
  transform: translateY(15px);
  transition: transform 300ms ease-in-out, opacity 150ms ease-in;
  opacity: 0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  position: relative;
  display: inline-block;
}

.team-style4 .social-links:after {
  background: #39b35d;
  bottom: -12px;
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 12px;
}

.team-style4 .social-links a:hover {
  opacity: 0.65;
}

.team-style4 .team-description-wrapper {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.team-style4 .team-description-content {
  display: table-cell;
  vertical-align: middle;
}

.team-style4 .team-member-img {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-style4 .bg-cover {
  background-color: rgba(255, 66, 41, 0);
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  transform: scale(2);
  transition: all 200ms ease-in-out;
  display: none;
  z-index: 1;
}

.team-style4 .team-member-img > img {
  transition: all 0.2s ease 0s;
}

.team-style4:hover .bg-cover {
  background-color: rgba(0, 0, 0, 0.83);
  display: block;
}

.team-style4:hover .social-links {
  transition-delay: 100ms, 100ms;
  opacity: 1;
  transform: translateY(0);
}

.team-style4:hover .about-me {
  transition-delay: 200ms, 200ms;
  opacity: 1;
  transform: translateY(0);
}

.team-style4:hover .team-member-img > img {
  filter: blur(4px) grayscale(4%);
}

.team-style4:hover .team-description {
  opacity: 1;
}

.filtering {
  margin-bottom: 10px;
}

.filtering span {
  border-bottom: 1px solid transparent;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 5px;
}

.filtering span:last-child {
  margin: 0;
}

.filtering .active {
  border-color: #39b35d;
  color: #39b35d;
}

@media screen and (max-width: 767px) {
  .filtering span {
    margin-right: 15px;
  }
}

.filtering-style2 span {
  font-size: 12px;
  font-weight: 700;
}

.gallery-style2 .project-grid {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.project-grid {
  display: block;
  position: relative;
  margin: 15px 0;
}

.project-grid-img img {
  display: block;
  width: 100%;
  height: auto;
}

.project-grid-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  overflow: hidden;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.project-grid-overlay h4 {
  margin-bottom: 0;
}

.project-grid-overlay h4 a {
  text-align: center;
}

.project-grid-overlay p {
  text-align: center;
}

.project-grid:hover .project-grid-overlay {
  opacity: 1;
}

.project-grid-overlay h4 a {
  font-size: 22px;
  opacity: 0;
}

.project-grid-overlay h4 a:hover {
  color: #39b35d;
}

.project-grid-overlay h4 a:after {
  content: "";
  display: block;
  width: 70px;
  height: 1px;
  background: #39b35d;
  margin: 15px auto;
}

.project-grid:hover h4 a {
  opacity: 1;
  line-height: normal;
}

.project-grid-overlay p {
  color: #666;
  font-size: 15px;
  margin: 0;
  opacity: 0;
}

.project-grid:hover p {
  opacity: 1;
}

.project-grid-overlay.theme {
  background: rgba(73, 192, 208, 0.95);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 5px solid rgba(0, 0, 0, 0.15);
}

.project-grid-overlay.theme h4 a {
  color: #fff;
}

.project-grid-overlay.theme h4 a:hover {
  color: rgba(255, 255, 255, 0.55);
}

.project-grid-overlay.theme h4 a:after {
  height: 1px;
  background: #fff;
}

.project-grid-overlay.theme p {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .project-grid-overlay h4 {
    line-height: 20px;
  }

  .project-grid-overlay h4 a {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .project-grid {
    margin: 20px 0 0 0;
  }

  .project-grid:hover p {
    top: 52%;
  }
}

.project-grid-style2 .project-details {
  position: relative;
  overflow: hidden;
}

.project-grid-style2 .project-details img {
  width: 100%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.project-grid-style2 .portfolio-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.project-grid-style2 .portfolio-icon a {
  position: absolute;
  top: 50%;
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  font-size: 26px;
  z-index: 9;
}

.project-grid-style2 .portfolio-title {
  padding: 15px 15px 0 15px;
  text-align: center;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.project-grid-style2 .portfolio-title h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.project-grid-style2 .portfolio-title p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 12px;
}

.project-grid-style2:hover .project-details img {
  -webkit-transform: scale3d(1.15, 1.15, 1);
  transform: scale3d(1.15, 1.15, 1);
}

.project-grid-style2:hover .portfolio-icon {
  opacity: 1;
}

.project-grid-style2:hover .portfolio-icon a {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.project-grid-style2:hover .portfolio-icon a:hover {
  color: #39b35d;
}

.portfolio-post-border {
  border: 1px solid #39b35d;
  bottom: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  visibility: hidden;
}

.project-grid-style2:hover .portfolio-post-border {
  visibility: visible;
}

.project-carousel h4:after {
  content: " ";
  background: #39b35d;
  height: 2px;
}

.project-single-text h4:after {
  content: " ";
  background: #39b35d;
  height: 2px;
}

.project-single-text p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
}

.project-single-text h5 {
  color: #232323;
  font-size: 18px;
  font-weight: 500;
  margin: 25px 0 20px;
}

.project-single-text h5:after {
  display: block;
  width: 70px;
  margin-top: 8px;
  content: " ";
  background: #39b35d;
  height: 2px;
}

.project-single-text ul li {
  color: #232323;
  font-size: 15px;
  font-weight: 500;
}

.project-single-text ul li i {
  color: #39b35d;
  font-weight: 500;
  font-size: 15px;
  padding-right: 10px;
}

.project-single-info {
  border: 1px solid #ececec;
  display: block;
  width: 100%;
  padding: 0 20px;
}

.project-single-info ul li {
  color: #777;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #ececec;
  padding: 12px 0;
}

.project-single-info ul li:last-child {
  border-bottom: none;
}

.project-single-info ul li span {
  display: inline-block;
  color: #232323;
  font-size: 15px;
  font-weight: 500;
  margin-right: 25px;
  width: 70px;
  max-width: 100%;
}

.project-single-info ul li .value {
  width: auto;
  margin-right: 0;
  color: #6f6f6f;
}

.project-single-img {
  padding-bottom: 30px;
}

.project-single-box-img img {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
}

.project-grid-style3 {
  position: relative;
  margin-bottom: 10px;
}

.project-grid-style3 .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.project-grid-style3 .inner-box .project-image {
  position: relative;
}

.project-grid-style3 .inner-box .project-image:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
  background: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
  background: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
  background: -ms-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
}

.project-grid-style3 .inner-box:hover .project-image:before {
  opacity: 0;
}

.project-grid-style3 .inner-box .project-image img {
  position: relative;
  width: 100%;
  display: block;
}

.project-grid-style3 .inner-box .overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  color: #ffffff;
  visibility: hidden;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  -moz-transform-origin: left top;
  transform-origin: left top;
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.project-grid-style3 .inner-box .overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: block;
  visibility: hidden;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  background-color: rgba(73, 192, 208, 0.95);
}

.project-grid-style3 .inner-box:hover .overlay:before {
  opacity: 1;
  visibility: visible;
}

.project-grid-style3 .inner-box .overlay-inner {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
  padding: 20px;
}

.project-grid-style3 .inner-box .overlay-inner .description {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.project-grid-style3 .inner-box .text {
  position: relative;
  color: #ffffff;
  margin-bottom: 20px;
}

.project-grid-style3 .inner-box .read-more {
  position: relative;
  font-size: 13px;
  font-weight: 700;
  color: #ffffff;
  padding-right: 22px;
}

.project-grid-style3 .inner-box .read-more .fa {
  position: relative;
  margin-right: 4px;
}

.project-grid-style3 .inner-box:hover .overlay {
  opacity: 1;
  visibility: visible;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

.project-grid-style3 .inner-box .project-desc {
  background: #fff;
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.project-grid-style3 .inner-box:hover .project-desc {
  background-color: #f9f8fc;
}

.project-grid-style3 .inner-box .project-desc .category {
  color: #39b35d;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 700;
  padding-bottom: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.project-grid-style3 .inner-box:hover .project-desc .category {
  color: #232323;
}

.project-grid-style3 .inner-box .project-desc .category:after {
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  width: 20%;
  display: block;
  margin-top: 10px;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.project-grid-style3 .inner-box:hover .project-desc .category:after {
  width: 100%;
  background: #39b35d;
}

.project-grid-style3 .inner-box .project-desc a {
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  line-height: 24px;
}

.project-grid-style3 .inner-box .project-desc a:hover {
  color: #39b35d;
}

@media screen and (max-width: 767px) {
  .project-grid-style3 .inner-box .overlay-inner {
    padding: 15px;
  }

  .project-grid-style3 .inner-box .text {
    margin-bottom: 15px;
  }
}

.project-grid-style4 {
  display: block;
  position: relative;
  margin: 0;
}

.project-grid-style4.portfolio-item-wrapper {
  display: block;
  text-decoration: none;
}

.project-grid-style4 .portfolio-img-container {
  display: block;
  position: relative;
}

.project-grid-style4 .portfolio-description {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fafafa;
  color: #000;
  padding: 15px;
  font-size: 0.85em;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .project-grid-style4 .portfolio-item-img {
    width: 100%;
    display: block;
  }

  .project-grid-style4 .portfolio-description {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    opacity: 0;
    transition: all 200ms ease-in-out;
  }

  .project-grid-style4 .portfolio-cover {
    background-color: rgba(255, 66, 41, 0);
    opacity: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    transform: scale(2);
    transition: all 200ms ease-in-out;
    display: none;
  }

  .project-grid-style4 .portfolio-title {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out, opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  .project-grid-style4 .portfolio-sub-title {
    transform: translateY(15px);
    transition: transform 300ms ease-in-out, opacity 150ms ease-in;
    opacity: 0;
    color: #fff;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: inline-block;
    font-size: 14px;
  }

  .project-grid-style4 .portfolio-sub-title:after {
    background: #39b35d;
    bottom: 10px;
    content: "";
    height: 1px;
    position: absolute;
    right: -20px;
    width: 12px;
  }

  .project-grid-style4 .portfolio-sub-title:before {
    background: #39b35d;
    bottom: 10px;
    content: "";
    height: 1px;
    position: absolute;
    left: -20px;
    width: 12px;
  }

  .project-grid-style4 .portfolio-description-wrapper {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
  }

  .project-grid-style4 .portfolio-description-content {
    display: table-cell;
    vertical-align: middle;
  }

  .project-grid-style4.portfolio-item-wrapper {
    backface-visibility: hidden;
    margin-bottom: 0;
    overflow: hidden;
  }

  .project-grid-style4 .portfolio-item-img {
    transition: all 0.2s ease 0s;
  }

  .project-grid-style4:hover .portfolio-cover {
    background-color: rgba(0, 0, 0, 0.83);
    display: block;
  }

  .project-grid-style4:hover .portfolio-title {
    transition-delay: 100ms, 100ms;
    opacity: 1;
    transform: translateY(0);
  }

  .project-grid-style4:hover .portfolio-sub-title {
    transition-delay: 200ms, 200ms;
    opacity: 1;
    transform: translateY(0);
  }

  .project-grid-style4:hover .portfolio-item-img {
    filter: blur(4px) grayscale(4%);
  }

  .project-grid-style4:hover .portfolio-description {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .project-grid-style4 .portfolio-description {
    position: static;
  }

  .project-grid-style4 .portfolio-title {
    font-size: 15px;
  }

  .project-grid-style4 .portfolio-sub-title {
    font-size: 15px;
    font-size: 13px;
    color: #6f6f6f;
  }
}

.accordion-style .card {
  background: transparent;
  box-shadow: none;
  margin-top: 0 !important;
  border: none;
}

.accordion-style .card-header {
  border: 0px;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background: none;
}

.accordion-style .btn-link {
  background: #39b35d !important;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  border: none;
  display: block;
  width: 100%;
  text-align: left;
  white-space: normal;
  border-radius: 4px;
  padding: 15px 30px 15px 15px;
  text-decoration: none;
}

.accordion-style .btn-link:hover {
  background: #39b35d !important;
  border: none;
  text-decoration: none;
}

.accordion-style .btn-link.collapsed:after {
  background: none;
  content: "+";
  right: 15px;
  left: inherit;
  font-size: 20px;
  height: auto;
  transform: none;
  width: auto;
  top: 15px;
}

.accordion-style .btn-link:after {
  background: none;
  content: "-";
  right: 17px;
  left: inherit;
  font-size: 20px;
  height: auto;
  transform: none;
  width: auto;
  top: 15px;
  position: absolute;
  color: #fff;
}

.accordion-style .card-body {
  padding: 20px;
  line-height: 24px;
  text-align: left;
}

.accordion-style2 .card {
  background: transparent;
  box-shadow: none;
  margin-top: 0 !important;
  border: none;
}

.accordion-style2 .card-header {
  border: 0px;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background: none;
}

.accordion-style2 .btn-link {
  background: #ededed !important;
  color: #232323;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  border: none;
  display: block;
  width: 100%;
  text-align: left;
  white-space: normal;
  border-radius: 0;
  padding: 15px 30px 15px 15px;
  text-decoration: none;
}

.accordion-style2 .btn-link:hover {
  background: #ededed !important;
  border: none;
  text-decoration: none;
}

.accordion-style2 .btn-link.collapsed:after {
  background: none;
  content: "+";
  right: 15px;
  left: inherit;
  font-size: 20px;
  height: auto;
  transform: none;
  width: auto;
  top: 15px;
}

.accordion-style2 .btn-link:after {
  background: none;
  content: "-";
  right: 17px;
  left: inherit;
  font-size: 20px;
  height: auto;
  transform: none;
  width: auto;
  top: 15px;
  position: absolute;
  color: #232323;
}

.accordion-style2 .card-body {
  padding: 10px 15px;
  line-height: 24px;
  text-align: left;
  background: #f9f9f9;
}

.services-single-menu {
  border: 1px solid #ececec;
}

.services-single-menu ul li {
  border-bottom: 1px solid #ececec;
}

.services-single-menu ul li:last-child {
  border-bottom: none;
}

.services-single-menu ul li.services-active {
  background: #39b35d;
}

.services-single-menu ul li.services-active a {
  color: #fff;
}

.services-single-menu ul li a {
  display: block;
  color: #232323;
  font-size: 15px;
  font-weight: 500;
  padding: 17px 0 17px 15px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.callback-box {
  background: #232323;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px 15px 25px 15px;
  position: relative;
}

.masonry {
  margin: 10px;
  padding: 0;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}

.masonry a {
  display: block;
  margin-top: 10px;
}

.masonry a:first-child {
  margin-top: 0;
}

.masonry-item {
  position: relative;
  overflow: hidden;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.masonry-item img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.masonry-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  border: 8px solid rgba(255, 255, 255, 0.65);
  padding: 3%;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.masonry-item:hover img {
  transform: scale(1.2);
}

.masonry-item:hover .masonry-item-overlay {
  position: absolute;
  opacity: 1;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.masonry-item:hover .masonry-item-overlay h4 {
  opacity: 1;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  text-align: center;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.masonry-item:hover .masonry-item-overlay ul {
  opacity: 1;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  text-align: center;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.masonry-item-overlay h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  bottom: 40px;
  margin: 0 0 20px 0;
  line-height: 20px;
}

.masonry-item-overlay ul {
  position: absolute;
  padding-left: 0;
  bottom: 25px;
  margin: 0;
}

.masonry-item-overlay ul li {
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding-left: 0;
  opacity: 0.7;
}

.masonry-item-overlay ul li:last-child:after {
  content: none;
}

.masonry-item-overlay ul li:after {
  content: " / ";
  font-size: 8px;
  font-weight: 700;
  margin: 0 5px;
}

.masonry-item-overlay ul li a {
  color: #fff;
}

.masonry-item:first-child {
  margin-top: 0;
}

@media screen and (min-width: 576px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 768px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 992px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media screen and (min-width: 1200px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

.masonry-4 {
  margin: 10px;
  padding: 0;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
}

.masonry-4 a {
  display: block;
  margin-top: 10px;
}

.masonry-4 a:first-child {
  margin-top: 0;
}

@media screen and (min-width: 576px) {
  .masonry-4 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 768px) {
  .masonry-4 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 992px) {
  .masonry-4 {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media screen and (min-width: 1200px) {
  .masonry-4 {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  padding: 13px 15px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;
}

.resp-tabs-list li:last-child {
  margin-right: 0;
}

.resp-tabs-list li i {
  font-size: 20px;
  padding-right: 5px;
  vertical-align: text-bottom;
}

.resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  clear: left;
}

.resp-tab-content {
  display: none;
  padding: 20px;
}

.resp-tabs-list li.resp-tab-active {
  border: 1px solid #39b35d;
  border-bottom: none;
  border-color: #39b35d !important;
  margin-bottom: -1px;
  padding: 12px 14px 14px 14px;
  border-top: 4px solid #39b35d !important;
  border-bottom: 0px #fff solid;
  border-bottom: none;
  background-color: #fff;
  color: #39b35d;
}

.resp-content-active,
.resp-accordion-active {
  display: block;
}

.resp-tab-content {
  border: 1px solid #c1c1c1;
  border-top-color: #c1c1c1;
  float: left;
  width: 100%;
}

h2.resp-accordion {
  cursor: pointer;
  display: none;
  font-size: 14px;
  border: 1px solid #c1c1c1;
  border-top: 0px solid #c1c1c1;
  margin: 0px;
  padding: 14px 15px;
  float: left;
  width: 100%;
}

h2.resp-tab-active {
  border-bottom: 0px solid #c1c1c1 !important;
  background-color: #39b35d !important;
  color: #fff;
}

h2.resp-tab-title:last-child {
  border-bottom: 12px solid #c1c1c1 !important;
  background: blue;
}

.resp-vtabs ul.resp-tabs-list {
  float: left;
  width: 30%;
}

.resp-vtabs .resp-tabs-list li {
  display: block;
  padding: 15px 15px !important;
  margin: 0 0 4px;
  cursor: pointer;
  float: none;
}

.resp-vtabs .resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  border: 1px solid #39b35d !important;
  float: left;
  width: 70%;
  min-height: 250px;
  clear: none;
}

.resp-vtabs .resp-tab-content {
  border: none;
  word-wrap: break-word;
}

.resp-vtabs li.resp-tab-active {
  position: relative;
  z-index: 1;
  margin-right: -1px !important;
  padding: 14px 15px 15px 11px !important;
  border-top: 1px solid;
  border: 1px solid #39b35d !important;
  border-left: 4px solid #39b35d !important;
  margin-bottom: 4px !important;
  border-right: 1px #fff solid !important;
}

.resp-arrow {
  border-color: transparent #232323 #232323 transparent;
  border-style: solid;
  border-width: 0 1px 1px 0;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 4px;
}

h2.resp-tab-active span.resp-arrow {
  border-color: #fff transparent transparent #fff;
  border-style: solid;
  border-width: 1px 0 0 1px;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 7px;
}

.resp-easy-accordion h2.resp-accordion {
  display: block;
}

.resp-easy-accordion .resp-tab-content {
  border: 1px solid #c1c1c1;
}

.resp-easy-accordion .resp-tab-content:last-child {
  border-bottom: 1px solid #c1c1c1;
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
  border-color: #39b35d !important;
}

h2.resp-accordion:first-child {
  border-top: 1px solid #c1c1c1;
}

h2.resp-accordion.resp-tab-active {
  border-color: #39b35d !important;
}

.tab-style4 .resp-tabs-list li.resp-tab-active,
.tab-style4 .resp-tab-content-active {
  background: #39b35d !important;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .tab-style5 ul.resp-tabs-list {
    text-align: center;
  }

  .tab-style5 ul.resp-tabs-list.text-left {
    padding-left: 15px;
  }

  .tab-style5 ul.resp-tabs-list li:last-child {
    margin-right: 0;
  }

  .tab-style5 ul.resp-tabs-list li.resp-tab-active {
    border-bottom-color: #39b35d;
    color: #39b35d;
  }

  .tab-style5 .resp-tab-content {
    border: 1px solid #e6e6e6;
    border-width: 1px 0 0 0;
    border-color: #39b35d;
  }
}

@media screen and (min-width: 768px) {
  .tab-style6 ul.resp-tabs-list {
    text-align: center;
    border: 1px solid #e6e6e6;
    border-width: 0 0 1px 0;
  }

  .tab-style6 ul.resp-tabs-list li:last-child {
    margin-right: 0;
  }

  .tab-style6 ul.resp-tabs-list li.resp-tab-active {
    border-bottom-color: #39b35d;
    color: #39b35d;
  }

  .tab-style6 .resp-tab-content {
    border: none;
    padding: 30px 20px;
  }

  .tab-style6 .resp-tabs-list .tab-box {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin-top: 8px;
  }

  .tab-style6 .resp-tabs-list li.resp-tab-active h6 {
    color: #39b35d;
  }

  .tab-style6 .resp-tabs-list .tab-box h6 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #6f6f6f;
    line-height: 12px;
  }

  .tab-style6 .resp-tabs-list .tab-box span {
    text-transform: lowercase;
    font-size: 12px;
  }

  .tab-style6 .resp-tabs-list li {
    font-size: 18px;
    border-bottom: 2px solid transparent !important;
    margin: 0;
    background: none !important;
    padding: 0 35px 15px 35px;
    text-transform: uppercase;
  }

  .tab-style6 .resp-tabs-list li.resp-tab-active {
    border-width: 0 0 2px 0 !important;
    border-color: #39b35d !important;
  }

  .tab-style6 ul.resp-tabs-list i {
    margin-right: 15px;
    border: 1px solid #d5d5d5;
    border-radius: 70px;
    width: 70px;
    height: 70px;
    line-height: 68px;
    font-size: 30px;
    padding: 0;
    vertical-align: middle;
  }

  .tab-style6 ul.resp-tabs-list li.resp-tab-active i {
    background: #39b35d;
    border-color: #39b35d;
    color: #fff;
  }
}

@media screen and (max-width: 991px) {
  .tab-style6 .resp-tab-content {
    padding: 20px;
  }

  .tab-style6 .resp-tabs-list li {
    padding: 0 15px 15px 15px;
  }
}

@media screen and (max-width: 767px) {
  ul.resp-tabs-list {
    display: none;
  }

  h2.resp-accordion {
    display: block;
  }

  h2.resp-accordion i {
    margin-right: 8px;
    font-size: 18px;
  }

  .resp-vtabs .resp-tab-content {
    border: 1px solid #c1c1c1;
  }

  .resp-vtabs .resp-tabs-container {
    border: none !important;
    float: none;
    width: 100%;
    min-height: 100px;
    clear: none;
  }

  .resp-accordion-closed {
    display: none !important;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid #c1c1c1 !important;
  }

  .resp-tab-content {
    background-color: #fff;
  }

  .tab-style6 .tab-box {
    display: inline-block;
    vertical-align: top;
  }

  .tab-style6 h2.resp-accordion i {
    margin-right: 15px;
  }

  .tab-style6 .tab-box h6 {
    margin-bottom: 2px;
    font-size: 14px;
  }

  .tab-style6 .tab-box span {
    font-size: 12px;
  }

  .tab-style6 .resp-tab-active h6 {
    color: #fff;
  }
}

.tab-style7 .resp-tabs-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.tab-style7 .resp-tabs-list li {
  position: relative;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 0 15px 15px 15px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;
  min-width: auto;
  color: #000;
  background: none !important;
}

.tab-style7 .resp-tabs-list li.resp-tab-active {
  border: 1px solid #39b35d;
  border-bottom: none;
  border-color: #39b35d !important;
  margin-bottom: -1px;
  border-top: 4px solid #39b35d !important;
  border-bottom: 0px #fff solid;
  border-bottom: none;
  background-color: #fff;
  color: #39b35d;
  color: #5e2ced;
  -ms-border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -o-border-top-left-radius: 5px;
  -ms-border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -o-border-top-right-radius: 5px;
  -ms-border-radius-top-left: 5px;
  -webkit-border-radius-top-left: 5px;
  -moz-border-radius-top-left: 5px;
  -o-border-radius-top-left: 5px;
  -ms-border-radius-topright: 5px;
  -webkit-border-radius-topright: 5px;
  -moz-border-radius-topright: 5px;
  -o-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.tab-style7 .resp-tabs-list li.resp-tab-active:after {
  content: "";
  background: #39b35d;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  margin: 0 auto;
  right: 0;
}

.tab-style7 .img-effect:hover {
  transform: translateY(-8px);
}

.tab-style7 .box-shadow-primary {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 992px) {
  .tab-style7 ul.resp-tabs-list {
    text-align: center;
  }

  .tab-style7 ul.resp-tabs-list.text-left {
    padding-left: 15px;
  }

  .tab-style7 ul.resp-tabs-list li:last-child {
    margin-right: 0;
  }

  .tab-style7 ul.resp-tabs-list li.resp-tab-active {
    color: #39b35d;
  }

  .tab-style7 .resp-tab-content {
    margin-top: 40px;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 991px) {
  .tab-style7 ul.resp-tabs-list {
    display: none;
  }

  .tab-style7 h2.resp-accordion {
    display: block;
  }

  .tab-style7 h2.resp-accordion i {
    margin-right: 8px;
    font-size: 18px;
  }

  .tab-style7 .resp-accordion-closed {
    display: none !important;
  }

  .tab-style7 .resp-tab-content {
    background-color: #fff;
  }
}

.price-table {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  position: relative;
}

.price-table-header {
  background: #39b35d;
  text-align: center;
  padding: 30px;
}

.price-table-recommended {
  position: relative;
  z-index: 9999;
  transform: scale(1.09);
}

.pricing-list > i {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 100%;
  color: #39b35d;
  display: inline-block;
  font-size: 30px;
  height: 80px;
  line-height: 78px;
  margin-bottom: 15px;
  width: 80px;
}

.price-table-header h5 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  padding: 5px 15px 15px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.price-table-header h4 {
  color: #fff;
  font-size: 60px;
  margin: 15px 0 10px;
}

.price-table-header h4 sup {
  font-size: 35px;
  margin: 0 5px;
}

.price-table-header span {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.price-table-content {
  padding: 20px;
}

.price-table-content ul {
  padding-left: 0;
  padding-bottom: 0;
}

.price-table-content ul li {
  display: block;
  list-style-type: none;
  color: #232323;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
}

.price-table-content ul li:before {
  content: "\f00c";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  display: inline-block;
  color: #39b35d;
  margin-right: 12px;
}

.price-table-content ul li:nth-child(2) {
  background: #eee;
}

.price-table-content a {
  display: inline-block;
  border: 2px solid #39b35d;
  background: #fff;
  font-family: "Montserrat", sans-serif;
  color: #232323;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding: 12px 15px;
  width: 100%;
  margin-top: 15px;
}

.price-table-content a:hover {
  background: #39b35d;
  color: #fff;
}

.pricing-list {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #ececec;
  padding: 30px 40px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.pricing-list.highlight {
  background: #39b35d;
  border-color: transparent;
}

.pricing-list.highlight .pricing-list-button {
  background: #fff;
  margin: 20px 15px 0;
  border: 2px solid #fff;
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.pricing-list.highlight .pricing-list-button:hover {
  background: #39b35d;
}

.pricing-list.highlight .pricing-list-button a {
  display: block;
  color: #39b35d;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.pricing-list.highlight .pricing-list-button:hover a {
  color: #fff;
}

.pricing-list h4 {
  color: #232323;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.pricing-list h2 {
  display: inline-block;
  color: #232323;
  letter-spacing: 2px;
}

.pricing-list h2 sup {
  color: #232323;
  font-size: 30px;
  font-weight: 500;
  vertical-align: sub;
}

.pricing-list span {
  display: inline-block;
  color: #39b35d;
  font-weight: 500;
}

.pricing-list ul {
  margin-top: 20px;
}

.pricing-list ul li {
  color: #666;
  font-size: 15px;
  font-weight: 400;
  padding: 7px;
}

.pricing-list ul li:after {
  content: "";
  display: block;
  width: 240px;
  max-width: 100%;
  height: 1px;
  background: #ececec;
  margin: 10px auto 0;
}

.pricing-list-button {
  background: #39b35d;
  margin: 20px 15px 0;
  border: 2px solid #39b35d;
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.pricing-list-button:hover {
  background: #fff;
}

.pricing-list-button a {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.pricing-list-button:hover a {
  color: #39b35d;
}

.price-table-style2 .price-table-header {
  background: #000;
}

.price-table-style2 .price-table-content a {
  border-color: #232323;
}

.price-table-style2 .price-table-content a:hover {
  background: #232323;
}

.price-table-style2 .price-table-content ul li:before {
  color: #232323;
}

@media screen and (max-width: 767px) {
  .price-table-recommended {
    position: relative;
    z-index: 9999;
    transform: scale(1);
  }
}

.single-plan {
  position: relative;
  z-index: 99;
  border: 1px solid #efefef;
  background: #fff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  -moz-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  -ms-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  -o-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
}

.single-plan .head-plan h4 {
  padding: 20px;
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.body-plan .price-plan {
  padding: 32px 0 43px;
  position: relative;
  background: #f7f7f7;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
}

.body-plan .price-plan h3 {
  margin: 0;
  z-index: 11;
  position: relative;
  font-weight: bold;
  color: #616161;
}

.body-plan .price-plan h3 sup {
  font-size: 18px;
  top: -15px;
  font-weight: bold;
  margin-right: 2px;
}

.body-plan .price-plan span {
  text-transform: uppercase;
  font-size: 12px;
  z-index: 11;
  position: relative;
}

.feat-plan {
  margin: 56px 0 33px;
}

.feat-plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feat-plan ul li {
  margin-bottom: 15px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
}

.feat-plan ul li:last-child {
  margin: 0;
}

.price-plan i {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  background: #39b35d;
}

.footer-plan {
  padding: 0 0 30px;
}

@media screen and (max-width: 767px) {
  .body-plan .price-plan {
    padding: 28px 0 38px;
  }

  .body-plan .price-plan h3 sup {
    font-size: 16px;
  }

  .feat-plan {
    margin: 45px 0 30px;
  }

  .feat-plan ul li {
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
}

.shop-category {
  -webkit-box-shadow: 1px 5px 22px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 5px 22px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 5px 22px -3px rgba(0, 0, 0, 0.1);
}

.shop-category li {
  border-color: #eaeaea;
  padding: 0 0 10px 40px;
}

.shop-category.list-style-5 li:before {
  left: 15px;
}

.label-offer {
  position: absolute;
  left: 0;
  top: 0;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  padding: 0px 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  z-index: 1;
}

.product-grid .border {
  float: left;
  width: 100%;
  position: relative;
}

.product-grid .product-info {
  padding: 15px;
  float: left;
  width: 100%;
  background: #fafafa;
  border-top: 1px solid #eeeeee;
  text-align: center;
  font-size: 18px;
}

.product-grid .product-info > a {
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
}

.product-grid .price {
  font-weight: 600;
}

.product-grid .product-block:hover > img {
  opacity: 0.65;
}

.product-grid .buttons {
  float: left;
  width: 100%;
}

.product-grid .buttons a {
  width: 50%;
  float: left;
  text-align: center;
  padding: 8px 0;
}

.control-top .owl-nav {
  margin: 0;
  position: absolute;
  right: -2px;
  top: -73px;
}

.control-top.owl-theme .owl-nav [class*="owl-"] {
  background: #fff !important;
  padding: 0 7px;
  width: 30px;
  height: 30px;
  margin: 0 2px;
  border-radius: 0;
  border: 1px solid #d5d5d5;
}

.control-top.owl-theme .owl-nav i {
  color: #6f6f6f;
  padding-top: 4px;
}

.v-middle-content {
  padding: 50px 8.5%;
  padding-top: 0;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  max-width: 65%;
  display: block;
}

.banner-content {
  letter-spacing: 2px;
}

.banner-content:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  content: "";
  opacity: 0;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.banner-content:hover:before {
  background: rgba(0, 0, 0, 0.65);
  opacity: 1;
}

.banner-content .subtitle {
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 600;
}

.banner-content .title {
  font-size: 44px;
  line-height: 44px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.banner-content:hover .title {
  margin-left: 20px;
}

.banner-content .view {
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid #373737;
  display: inline-block;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

.banner-content:hover .view {
  margin-left: 20px;
  border-bottom: 1px solid #fff;
}

.feature-product-grid .border {
  float: left;
  width: 100%;
  background: #fafafa;
}

.feature-product-grid .product-block {
  width: 90px;
  height: 90px;
  border: 1px solid #eee;
  border-width: 0 1px 0 0;
  float: left;
}

.feature-product-grid .product-info {
  max-width: 61%;
  padding: 8px 15px;
  float: left;
}

.product-detail .label-sale {
  margin-left: 5px;
  vertical-align: top;
  padding: 2px 7px;
}

.product-detail .offer-price {
  text-decoration: line-through;
  color: red;
}

.product-rating .fas:hover {
  color: #232323;
}

.shop-cart-table {
  width: 100%;
  overflow-x: auto;
}

.shop-cart-table .shop-cart td {
  line-height: 26px;
  vertical-align: middle;
  padding: 16px 8px;
}

.table.cart-sub-total {
  border-color: transparent;
  vertical-align: middle;
  padding: 10px 30px;
}

.table.cart-sub-total td,
.table.cart-sub-total th {
  border-color: transparent;
  vertical-align: middle;
  padding: 10px 30px;
}

.table.cart-sub-total td {
  padding-right: 0;
}

.shop-cart-table input[type="text"] {
  height: 35px;
  width: 50px;
  margin: 0;
}

.product-list .product-block:hover > img {
  opacity: 0.65;
}

.product-list .buttons {
  float: left;
  width: 100%;
}

.product-list .buttons a {
  float: left;
  text-align: center;
  padding: 8px 23px;
}

.product-list .buttons a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 991px) {
  .categories .collapse-sm:after {
    font-family: Font awesome\5 Free;
    content: "\f105";
    float: right;
  }

  .categories .collapse-sm.current:after {
    font-family: Font awesome\5 Free;
    content: "\f107";
    float: right;
  }

  .categories .shop-category {
    display: none;
  }
}

.latest_blog_inner {
  overflow: hidden;
}

.image_blog {
  position: relative;
}

.image_blog > a:after {
  background: #39b35d none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.image_blog > a:hover:after {
  opacity: 0.6;
}

.image_blog a img {
  max-width: 100%;
}

.image_blog .img_blog_text {
  bottom: 35px;
  position: absolute;
  padding: 0 20px;
}

.image_blog .img_blog_text h5 {
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0.26px;
  padding-bottom: 10px;
  margin-bottom: 0;
  line-height: normal;
}

.image_blog .img_blog_text h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 24px;
}

.text_blog {
  background: #fafafa none repeat scroll 0 0;
  padding: 35px 40px 25px;
  transition: all 0.3s linear 0s;
}

.text_blog:hover {
  background: #39b35d;
}

.text_blog h5 {
  color: #616161;
  font-size: 13px;
  letter-spacing: 0.26px;
  padding-bottom: 18px;
  margin: 0;
  line-height: normal;
  transition: all 300ms linear 0s;
}

.text_blog:hover h5 {
  color: #ffffff;
}

.text_blog h3 {
  color: #282828;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 24px;
}

.text_blog:hover h3 {
  color: #ffffff;
}

.text_blog:hover h3 a {
  color: #ffffff;
}

.text_blog p {
  margin: 0;
  padding: 20px 0;
  transition: all 300ms linear 0s;
}

.text_blog:hover p {
  color: #ffffff;
}

.text_blog .blog_user {
  border-top: 2px solid #ececec;
  padding-top: 15px;
}

.text_blog .blog_user h4 {
  color: #616161;
  font-size: 15px;
  margin-bottom: 0;
  line-height: normal;
  letter-spacing: 0.3px;
  transition: all 300ms linear 0s;
}

.text_blog .blog_user h4 a {
  color: #39b35d;
  transition: all 300ms linear 0s;
}

.text_blog:hover .blog_user h4 {
  color: #ffffff;
}

.text_blog:hover .blog_user h4 a {
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .text_blog {
    padding: 16px 20px;
  }
}

.blog-grid {
  position: relative;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.blog-grid img {
  width: 100%;
}

.blog-style1 .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background: #fff;
  height: 100%;
}

.blog-style1 .item .post-img {
  position: relative;
  overflow: hidden;
}

.blog-style1 .item .post-img:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.blog-style1 .item .post-img img {
  width: 100%;
  -webkit-filter: none;
  filter: none;
  transition: 0.3s;
}

.blog-style1 .item .content {
  padding: 30px;
  text-align: left;
}

.blog-style1 .item .content .tag {
  font-size: 12px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.blog-style1 .item .content .read-more {
  margin-top: 20px;
  font-weight: 600;
}

.blog-style1 .item .content .read-more:hover {
  text-decoration: underline;
}

.blog-style1 .item .content p {
  margin-bottom: 10px;
}

.blog-style1 .item .content h5 {
  font-size: 16px;
  line-height: normal;
  text-transform: inherit;
}

.blog-style2 .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background: #fff;
  height: 100%;
}

.blog-style2 .item .post-img {
  position: relative;
  overflow: hidden;
}

.blog-style2 .item .post-img:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.blog-style2 .item .post-img img {
  width: 100%;
  -webkit-filter: none;
  filter: none;
  transition: 0.3s;
}

.blog-style2 .item .content {
  padding: 30px;
  text-align: left;
}

.blog-style2 .item .content .tag {
  font-size: 12px;
  margin-bottom: 0;
  letter-spacing: 1px;
}

.blog-style2 .item .content .read-more {
  margin-top: 20px;
  font-weight: 600;
}

.blog-style2 .item .content .read-more:hover {
  text-decoration: underline;
}

.blog-style2 .item .content h5 {
  font-size: 16px;
  line-height: normal;
  text-transform: inherit;
}

@media screen and (max-width: 991px) {
  .blog-style2 .item .content {
    padding: 25px;
  }
}

@media screen and (max-width: 767px) {
  .blog-style2 .item .content {
    padding: 20px;
  }

  .blog-style2 .item .content h5 {
    font-size: 15px;
  }
}

.blog-style6 .blog-item {
  float: left;
  width: 100%;
}

.blog-style6 .blog-item-thumbnail img {
  width: 100%;
  border-radius: 3px;
}

.blog-style6 .blog-meta {
  text-transform: uppercase;
  width: 24%;
  float: left;
  padding: 16px 25px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
  overflow: hidden;
  color: #fff;
  background-color: #39b35d;
}

.blog-style6 .date-day {
  font-weight: 600;
  font-size: 22px;
  line-height: 1;
  margin-bottom: 5px;
}

.blog-style6 .date-month {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
}

.blog-style6 .blog-title {
  float: left;
  width: 76%;
  padding-left: 20px;
}

.blog-style6 .blog-title a {
  font-size: 18px;
  line-height: 1.25;
  margin-bottom: 18px;
  font-weight: 600;
}

.blog-style6 .post-cat {
  color: #999;
  float: left;
  width: 76%;
  padding-left: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 5px;
}

.blog-style6 .blog-item-content {
  position: relative;
  float: left;
  width: 100%;
}

.blog-style6 .blog-item-thumbnail {
  margin-bottom: 20px;
}

.blog-style6 .blog-meta span {
  display: inline-block;
  margin-right: 15px;
}

@media screen and (max-width: 1199px) {
  .blog-style6 .blog-meta {
    padding: 15px;
  }

  .blog-style6 .blog-title a {
    font-size: 16px;
    line-height: 1;
  }
}

@media screen and (max-width: 991px) {
  .blog-style6 .blog-meta {
    padding: 10px;
  }

  .blog-style6 .date-day {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .blog-style6 .post-cat {
    padding-left: 15px;
    margin-bottom: 0;
  }

  .blog-style6 .blog-title {
    padding-left: 15px;
    line-height: 22px;
  }

  .blog-style6 .blog-title a {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 767px) {
  .blog-style6 .post-cat {
    width: 70%;
    padding-left: 15px;
  }

  .blog-style6 .blog-title {
    width: 70%;
    padding-left: 15px;
  }

  .blog-style6 .blog-title a {
    font-size: 14px;
  }

  .blog-style6 .blog-meta {
    padding: 12px 10px 8px 10px;
    width: 80px;
  }

  .blog-style6 .blog-item-thumbnail {
    margin-bottom: 15px;
  }

  .blog-style6 .date-day {
    margin: 0;
  }
}

.data-box-grid {
  display: inline-block;
}

.data-box-grid:before {
  content: "/";
  padding-right: 5px;
}

.data-box-grid h5 {
  font-size: 12px;
  line-height: normal;
  letter-spacing: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  color: #999;
}

.blog-grid .data-box-grid p {
  font-size: 12px;
  margin-bottom: 0;
  padding: 0;
  border: none;
  color: #999;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}

.blog-grid-text {
  padding: 25px;
  position: relative;
}

.blog-grid-text > span {
  color: #39b35d;
  font-size: 13px;
  padding-right: 5px;
}

.blog-grid-text h4 {
  font-size: 16px;
  line-height: normal;
  margin-bottom: 3px;
}

.blog-grid-text ul li {
  display: inline-block;
  color: #999;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 25px 10px 0;
}

.blog-grid-text ul li i {
  color: #39b35d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.blog-grid-text p {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  line-height: 170%;
  padding: 15px 0 0 0;
}

.owl-carousel .blog-grid-simple {
  margin: 10px 15px 25px;
}

.blog-grid-simple-date {
  width: 100%;
}

.blog-grid-simple {
  position: relative;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ececec;
  box-shadow: 0 10px 15px 0 rgba(50, 50, 50, 0.16);
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -moz-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  padding: 30px;
}

.blog-grid-simple h4 {
  color: #232323;
  line-height: normal;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 15px;
}

.blog-grid-simple h4 a {
  color: #232323;
  line-height: normal;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 15px;
}

.blog-grid-simple h4 a:hover {
  color: #39b35d;
}

.blog-grid-simple p {
  color: #aaa;
  font-size: 15px;
  font-weight: 400;
}

.blog-grid-simple-date i {
  color: #888;
  font-size: 20px;
}

.blog-grid-simple-date h5 {
  color: #888;
  font-size: 13px;
  margin-top: 3px;
  margin-bottom: 0;
  display: inline-block;
  margin-left: 8px;
  line-height: normal;
}

.blog-grid-simple-content {
  border-top: 1px solid #ececec;
  padding-top: 20px;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.blog-grid-simple-content a {
  display: inline-block;
  color: #39b35d;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
}

.blog-grid-simple-content a:after {
  content: "\f105";
  font-size: 14px;
  font-weight: 700;
  font-family: Font awesome\5 Free;
  color: #39b35d;
  margin-left: 10px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.blog-list-left-heading:after,
.blog-title-box:after {
  content: "";
  height: 2px;
}

.blog-grid-simple-content a:hover {
  color: #232323;
}

.blog-grid-simple-content a:hover:after {
  color: #232323;
}

.blog-list-simple {
  margin: 0 0 35px 0;
}

.blog-list-simple:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.data-box-simple {
  display: block;
  float: right;
  position: absolute;
  background: #39b35d;
  left: 20px;
  top: 5px;
  padding: 5px;
  min-width: 60px;
  text-align: center;
}

.blog-list-simple-text > span {
  color: #39b35d;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.data-box-simple h4 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 2px;
  border-bottom: 1px solid #fff;
  margin-bottom: 5px;
  line-height: normal;
}

.data-box-simple p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.blog-list-simple-text {
  color: #999999;
}

.blog-list-simple-text p {
  border-top: 1px solid #ececec;
  color: #999999;
  font-size: 15px;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 0;
  padding: 15px 0;
}

.blog-list-simple-text .meta {
  margin: 0 0 5px 0;
}

.blog-list-simple-text li {
  display: inline-block;
  font-size: 12px;
  color: #777;
  margin: 5px;
}

.blog-list-simple-text h4 {
  color: #232323;
  font-size: 20px;
  font-weight: 500;
  margin: 5px 0;
  line-height: normal;
}

.blog-list-simple-text ul {
  margin: 10px 0;
}

.blog-list-simple-text ul li {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 0;
}

.blog-list-simple-text ul li i {
  margin-right: 5px;
  font-size: 14px;
}

.blogs .post {
  margin-bottom: 30px;
}

.blogs .post .content {
  padding: 30px;
  background: #fafafa;
}

.blogs .post .content .post-title h5 {
  font-size: 26px;
  line-height: normal;
  font-weight: 500;
  margin-bottom: 0;
}

.blogs .post .content .post-title h5 a {
  color: #232323;
}

.blogs .post .content .post-title h5 a:hover {
  color: #f24259;
}

.blogs .post .content .meta {
  margin-bottom: 15px;
}

.blogs .post .content .meta li {
  display: inline-block;
  font-size: 12px;
  color: #777;
  margin: 5px;
}

.blogs .post .content .special {
  padding: 15px;
  margin: 30px 0;
  border-left: 2px solid #111;
  background: #f7f7f7;
  font-size: 16px;
}

.blogs .post .content .btn {
  border-color: #6f6f6f;
  color: #6f6f6f;
  min-width: auto;
  padding: 6px 20px;
  font-size: 12px;
}

.blogs .post .content .btn:hover {
  color: #fff;
  border-color: #f24259;
}

.blogs .post .share-post {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px dashed #ddd;
  text-align: left;
}

.blogs .post .share-post span {
  font-weight: 700;
}

.blogs .post .share-post ul {
  float: right;
}

.blogs .post .share-post ul li {
  display: inline-block;
  margin: 0 10px;
}

.blogs .post .post-img img {
  width: 100%;
}

.blogs .posts .title-g h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 10px;
}

.blogs .comments-area .comment-box {
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid #eee;
}

.blogs .comments-area .comment-box:nth-child(odd) {
  margin-left: 80px;
}

.blogs .comments-area .comment-box:last-child {
  margin-bottom: 30px;
}

.blogs .comment-box .author-thumb {
  width: 80px;
  float: left;
}

.blogs .comment-box .comment-info {
  margin-left: 100px;
}

.blogs .comment-box .comment-info h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.blogs .comment-box .comment-info .reply {
  margin-top: 10px;
  font-weight: 600;
}

.blogs .comment-box .comment-info .reply i {
  padding-right: 5px;
  font-size: 12px;
}

.pagination {
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.pagination ul {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0 auto;
  padding: 0;
}

.pagination li {
  display: inline;
}

.pagination a {
  float: left;
  padding: 0 18px;
  line-height: 40px;
  text-decoration: none;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  background: #fff;
}

.pagination a:hover {
  background-color: #232323;
  color: #fff;
}

.pagination .active a {
  background-color: #f7f7f7;
  color: #999;
  cursor: default;
}

.pagination .disabled span {
  color: #999;
  background-color: transparent;
  cursor: default;
}

.pagination .disabled a {
  color: #999;
  background-color: transparent;
  cursor: default;
}

.pagination .disabled a:hover {
  color: #999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
}

.blockquote {
  background: #f1f1f1;
  border-left: 4px solid #39b35d;
  font-size: 16px;
  font-weight: 500;
  margin: 25px 0;
  padding: 20px 30px 30px;
}

.blockquote p {
  font-size: 15px;
  font-weight: 500;
  line-height: 190%;
  color: #232323;
  letter-spacing: 0.5px;
}

.blockquote h4 {
  font-size: 20px;
  font-weight: 400;
  color: #232323;
  letter-spacing: 0.5px;
}

.side-bar .widget {
  margin-bottom: 30px;
}

.side-bar .widget:last-child {
  margin-bottom: 0;
}

.side-bar .widget .widget-title h6 {
  position: relative;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.side-bar .widget .widget-title h6:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #39b35d;
  position: absolute;
  bottom: 0;
  left: 0;
}

.side-bar .widget li {
  position: relative;
  padding: 0 0 0 12px;
  margin: 0 0 9px 0;
  color: #6f6f6f;
}

.side-bar .widget li:last-child {
  margin: 0;
}

.side-bar .widget li:after {
  content: "";
  width: 5px;
  height: 1px;
  background-color: #6f6f6f;
  position: absolute;
  top: 12px;
  left: 0;
}

.side-bar .widget .social-listing {
  margin-bottom: 0;
}

.side-bar .widget .social-listing li {
  list-style: none;
  display: inline-block;
}

.side-bar .widget .social-listing li:after {
  background: none;
}

.side-bar .widget .social-listing li:first-child {
  padding-left: 0;
}

.side-bar .search form input {
  width: calc(100% - 50px);
  height: 50px;
  padding: 0 10px;
  margin: 0;
  border: 1px solid #d1d1d1;
  background: #f7f7f7;
}

.side-bar .search form button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #1c1c1c;
  color: #fff;
  border: 0;
  float: right;
}

@media screen and (max-width: 767px) {
  .blog-grid-simple-content a:after {
    display: none;
  }
}

.count-back-box {
  width: 100%;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.count-back-box h1 {
  color: #232323;
  font-size: 40px;
  font-weight: 500;
}

.error-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.error-box h2 {
  color: rgba(204, 204, 204, 0.2);
  font-size: 250px;
  font-weight: 600;
  letter-spacing: 10px;
}

.error-box-text h1 {
  font-size: 200px;
  color: #39b35d;
  line-height: 1;
}

.error-box-text h3 {
  font-size: 40px;
  color: #232323;
  line-height: normal;
}

.error-box-text h4 {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .error-box {
    padding: 30px;
  }

  .back-box {
    display: none;
  }

  .error-box-text h1 {
    font-size: 100px;
  }
}

ul.countdown li {
  border-right: 1px solid #626262;
  display: inline-block;
  padding: 0 30px;
  text-align: center;
}

ul.countdown li:last-child {
  border: medium none;
  padding-right: 0;
}

ul.countdown li span {
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

ul.countdown li span::before {
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
}

ul.countdown li p.timeRefDays,
ul.countdown li p.timeRefHours,
ul.countdown li p.timeRefMinutes,
ul.countdown li p.timeRefSeconds {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  ul.countdown li {
    padding: 0 8px;
  }

  .social-links li {
    margin-right: 15px;
  }

  ul.countdown li span {
    font-size: 22px;
  }
}

.social-icon-style1 {
  margin-bottom: 0;
}

.social-icon-style1 li {
  text-align: center;
  margin-right: 4px;
  display: inline-block;
}

.social-icon-style1 li a {
  background: #39b35d;
  border: 1px solid #39b35d;
  color: #fff;
  border-radius: 35px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
}

.social-icon-style1 li a:hover {
  background: #fff;
  color: #39b35d;
}

.social-icon-style2 {
  margin-bottom: 0;
}

.social-icon-style2 li {
  text-align: center;
  margin-right: 5px;
  display: inline-block;
}

.social-icon-style2 li:last-child {
  margin-right: 0;
}

.social-icon-style2 li a {
  background: #f9f9f9;
  border: 1px solid #39b35d;
  color: #39b35d;
  border-radius: 35px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
}

.social-icon-style2 li a:hover {
  background: #39b35d;
  color: #fff;
}

.social-icon-style3 {
  margin-bottom: 0;
}

.social-icon-style3 li {
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.social-icon-style3 li a {
  color: #6f6f6f;
  display: inline-block;
  font-size: 16px;
}

.social-icon-style3 li a:hover {
  color: #39b35d;
}

.social-icon-style4 {
  padding: 0;
  float: right;
  margin: 0;
}

.social-icon-style4 li {
  font-size: 14px;
  list-style-type: none;
  float: left;
  width: 35px;
  text-align: center;
  margin: 0;
  padding: 13px 0;
}

.social-icon-style4 li a {
  opacity: 0.7;
  color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.social-icon-style5 li {
  display: inline-block;
  margin-right: 30px;
}

.social-icon-style5 li:last-child {
  margin-right: 0;
}

.social-icon-style5 li a {
  font-size: 22px;
}

.social-icon-style5 li a:hover {
  color: #fff;
}

.social-icon-style6 {
  margin-bottom: 0;
}

.social-icon-style6 li {
  text-align: center;
  margin-right: 5px;
  display: inline-block;
}

.social-icon-style6 li:last-child {
  margin-right: 0;
}

.social-icon-style6 li a {
  border: 1px solid #6f6f6f;
  color: #6f6f6f;
  border-radius: 35px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
}

.social-icon-style6 li a:hover {
  background: #232323;
  color: #fff;
}

#map {
  height: 400px;
  width: 100%;
}

.contact-info-section {
  border-bottom: 1px solid #ececec;
  padding: 25px 0;
  margin-bottom: 0;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.contact-info-section i {
  width: 25px;
  height: 25px;
  padding-top: 7px;
  border-radius: 50%;
  background: #39b35d;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
  line-height: 11px;
}

.contact-info-section h4 {
  color: #232323;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: normal;
}

.contact-info-section p {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.contact-box {
  text-align: center;
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 2px;
  -webkit-box-shadow: 0 20px 80px rgba(0, 0, 0, 0.12);
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.12);
  margin: 10px 0;
  padding: 20px 15px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.contact-box i {
  background: #39b35d;
  color: #fff;
  font-size: 35px;
  margin: 10px 0 20px;
  height: 75px;
  width: 75px;
  line-height: 75px;
  border-radius: 50%;
}

.contact-box h4 {
  color: #232323;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 5px;
  line-height: normal;
}

.contact-box span {
  color: #999;
  font-size: 14px;
}

.newsletter-form input {
  display: inline-block;
  background: #fff;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  outline: 0;
  border: 2px solid #eee;
  min-width: 270px;
  max-width: 100%;
  -moz-border-radius-topleft: 30px;
  -webkit-border-top-left-radius: 30px;
  border-top-left-radius: 30px;
  -moz-border-radius-bottomleft: 30px;
  -webkit-border-bottom-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 12px 20px;
  width: auto;
}

.newsletter-form button {
  display: inline-block;
  background: #39b35d;
  cursor: pointer;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  -moz-border-radius-topright: 30px;
  -webkit-border-top-right-radius: 30px;
  border-top-right-radius: 30px;
  -moz-border-radius-bottomright: 30px;
  -webkit-border-bottom-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 14px 20px;
}

.newsletter-form button:hover {
  background: #232323;
}

@media screen and (max-width: 767px) {
  .newsletter-form button {
    margin-top: 15px;
    border-radius: 30px;
    width: 100%;
    display: block;
  }

  .newsletter-form input {
    border-radius: 30px;
    width: 100%;
    display: block;
  }
}

footer {
  padding: 30px 0 0;
  background: #1f1f1f;
  color: #939393;
}

footer p {
  margin-bottom: 0;
}

footer h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 1;
  position: relative;
}

footer h3:after {
  content: "";
  display: block;
  width: 70px;
  height: 2px;
  background: #39b35d;
  margin-top: 12px;
}

.footer-social-icons ul {
  margin-bottom: 0;
}

.footer-social-icons ul li {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.footer-social-icons ul li a {
  color: #939393;
  display: block;
  font-size: 15px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  width: 35px;
}

.footer-social-icons ul li:hover {
  background: #39b35d;
  border-color: #39b35d;
}

.footer-social-icons ul li:hover i {
  color: #fff;
}

.footer-social-icons.small ul li {
  margin-bottom: 0;
}

.footer-social-icons.small ul li a {
  font-size: 12px;
  height: 25px;
  line-height: 26px;
  width: 25px;
}

.footer-social-icons2 ul {
  margin-bottom: 0;
}

.footer-social-icons2 ul li {
  display: inline-block;
  border: 1px solid #6f6f6f;
  border-radius: 50%;
  color: #6f6f6f;
  margin-right: 10px;
  margin-bottom: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.footer-social-icons2 ul li a {
  color: #939393;
  display: block;
  font-size: 15px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  width: 35px;
}

.footer-social-icons2 ul li:hover {
  background: #39b35d;
  border-color: #39b35d;
}

.footer-social-icons2 ul li:hover i {
  color: #fff;
}

.footer-subscribe {
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}

.footer-subscribe input {
  background: #fff;
  border-radius: 6px;
  font-size: 14px;
  border: 0px solid;
  height: 40px;
  outline: none;
  box-shadow: none;
  padding: 6px 95px 6px 12px;
  margin-bottom: 0;
}

.footer-subscribe input:focus {
  background: #fff;
  border-radius: 6px;
  font-size: 14px;
  border: 0px solid;
  height: 40px;
  outline: none;
  box-shadow: none;
  padding: 6px 95px 6px 12px;
  margin-bottom: 0;
}

.footer-subscribe .butn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  -webkit-border-radius: 0;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius: 0;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  line-height: 15px;
}

.footer-subscribe .butn:after {
  -webkit-border-radius: 0;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius: 0;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #e5e5e5;
}

.footer-subscribe .butn:hover {
  -webkit-border-radius: 0;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius: 0;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.footer-subscribe .butn:hover:after {
  -webkit-border-radius: 0;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius: 0;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.footer-subscribe .butn span {
  color: #fff;
}

.footer-subscribe .butn:hover span {
  color: #232323;
}

.footer-subscribe .butn.theme:after {
  background: #000;
}

.footer-subscribe .butn.theme:hover span {
  color: #fff;
}

.footer-subscribe .butn.white {
  color: #232323;
}

.footer-subscribe .butn.white:after {
  background: #232323;
}

.footer-subscribe .butn.theme.white-hover:hover {
  background: #fff;
}

.footer-subscribe .butn.theme.white-hover:hover span {
  color: #232323;
}

.footer-subscribe .butn.theme.white-hover:after {
  background: #fff;
}

.footer-subscribe .butn.theme.grey-hover:hover {
  background: #e5e5e5;
}

.footer-subscribe .butn.theme.grey-hover:hover span {
  color: #232323;
}

.footer-subscribe .butn.theme.grey-hover:after {
  background: #e5e5e5;
}

.footer-list {
  margin: 0;
}

.footer-list li {
  list-style-type: none;
  color: #939393;
  padding: 6px 0;
}

.footer-list li:first-child {
  padding-top: 0;
}

.footer-list li a {
  font-size: 15px;
  font-weight: 400;
  color: #939393;
}

.footer-list li a:before {
  content: "\f105";
  font-weight: 700;
  vertical-align: bottom;
  font-family: Font awesome\5 Free;
  color: #939393;
  padding-right: 8px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.footer-list li a:hover {
  color: #fff;
}

.footer-list li a:hover:before {
  color: #fff;
}

.footer-list-style2 {
  margin: 0;
}

.footer-list-style2 li {
  list-style-type: none;
  color: #fff;
  padding: 6px 0;
}

.footer-list-style2 li:first-child {
  padding-top: 0;
}

.footer-list-style2 li a {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-list-style2 li a:before {
  content: "\f105";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  color: #fff;
  padding-right: 8px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.footer-list-style2 li a:hover {
  opacity: 0.65;
}

.footer-list-style2 li a:hover:before {
  opacity: 0.65;
}

.footer-list-style3 {
  margin: 0;
}

.footer-list-style3 li {
  list-style-type: none;
  color: #6f6f6f;
  padding: 6px 0;
}

.footer-list-style3 li:first-child {
  padding-top: 0;
}

.footer-list-style3 li a {
  font-size: 15px;
  font-weight: 400;
  color: #6f6f6f;
}

.footer-list-style3 li a:before {
  content: "\f105";
  font-weight: 700;
  font-family: Font awesome\5 Free;
  color: #6f6f6f;
  padding-right: 8px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.footer-list-style3 li a:hover {
  color: #232323;
}

.footer-list-style2 li a:hover:before {
  color: #232323;
}

.footer-recent-post {
  margin: 15px 0;
}

.footer-recent-post-thumb {
  float: left;
  width: 25%;
}

.footer-recent-post-content {
  float: left;
  width: 75%;
  padding-left: 10px;
}

.footer-recent-post-content a {
  display: block;
  color: #939393;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin-top: -5px;
}

.footer-recent-post-content a:hover {
  color: #fff;
}

.footer-recent-post-content span {
  font-size: 13px;
  font-weight: 300;
  color: #fff;
}

.footer-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  text-align: center;
  background: #191919;
  color: #939393;
}

.footer-bar span {
  color: #39b35d;
  font-size: 15px;
  font-weight: 400;
}

.footer-top-bar {
  background: #191919;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

footer h3.footer-title-style2 {
  margin-bottom: 28px;
}

h3.footer-title-style2:after {
  width: 100%;
  height: 1px;
  background: #363637;
  margin-top: 15px;
}

.footer-title-style3 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 28px;
  color: #fff;
}

.footer-title-style3:after {
  content: "";
  width: 60px;
  height: 2px;
  background: #39b35d;
  position: absolute;
  bottom: 0;
  left: 20px;
}

.footer-title-style3:before {
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-title-style4:after {
  margin-left: auto;
  margin-right: auto;
}

h3.footer-title-style4 {
  margin-bottom: 20px;
}

h3.footer-title-style5 {
  color: #fff;
}

h3.footer-title-style5:after {
  background: #fff;
}

footer h3.footer-title-style6 {
  margin-bottom: 28px;
  color: #636363;
}

h3.footer-title-style6:after {
  width: 100%;
  height: 1px;
  background: #d0d0d0;
  margin-top: 15px;
}

footer h3.footer-title-style7 {
  margin-bottom: 30px;
  padding-bottom: 20px;
}

footer h3.footer-title-style7:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 18px;
  height: 1px;
  margin: 0 0 0;
  width: 70px;
  margin: 10px 0;
  background-color: #39b35d;
}

footer h3.footer-title-style7:before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #39b35d;
  margin-bottom: 10px;
}

.modal-lg,
.modal-xl {
  max-width: 900px !important;
}
.newsletter-subscribe-button {
  z-index: 100;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px !important;
  }
}

@media screen and (max-width: 767px) {
  footer {
    padding-top: 30px;
  }

  .footer-bar {
    margin-top: 30px;
  }

  .footer-subscribe .butn {
    line-height: 22px;
  }

  .footer-bar p {
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

@media screen and (max-width: 767px) {
  footer {
    padding-top: 30px;
  }

  .footer-bar {
    margin-top: 30px;
  }

  .footer-subscribe .butn {
    line-height: 22px;
  }

  .footer-bar p {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

.fix_tel {
  position: fixed;
  bottom: 15px;
  right: 0;
  z-index: 999;
}

.fix_tel {
  top: auto;
  bottom: auto;
  right: auto;
  left: auto;
  bottom: 15px;
  left: 5px;
}

.ring-alo-phone {
  background-color: transparent;
  cursor: pointer;
  height: 80px;
  position: absolute;
  transition: visibility 0.5s ease 0s;
  visibility: hidden;
  width: 80px;
  z-index: 200000 !important;
}

.ring-alo-phone.ring-alo-show {
  visibility: visible;
}

.ring-alo-phone.ring-alo-green .ring-alo-ph-circle {
  border-color: #f00;
}

.ring-alo-phone.ring-alo-green .ring-alo-ph-circle {
  background-color: #f00;
}

.ring-alo-phone.ring-alo-green .ring-alo-ph-circle {
  border-color: #f00;
  opacity: 0.5;
}

.ring-alo-ph-circle {
  animation: 1.2s ease-in-out 0s normal none infinite running
    ring-alo-circle-anim;
  background-color: transparent;
  border: 2px solid rgba(30, 30, 30, 0.4);
  border-radius: 100%;
  height: 70px;
  left: 10px;
  opacity: 0.1;
  position: absolute;
  top: 12px;
  transform-origin: 50% 50% 0;
  transition: all 0.5s ease 0s;
  width: 70px;
}

.ring-alo-phone.ring-alo-green .ring-alo-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75 !important;
}

.ring-alo-ph-circle-fill {
  animation: 2.3s ease-in-out 0s normal none infinite running
    ring-alo-circle-fill-anim;
  background-color: #000;
  border: 2px solid transparent;
  border-radius: 100%;
  height: 30px;
  left: 30px;
  opacity: 0.1;
  position: absolute;
  top: 33px;
  transform-origin: 50% 50% 0;
  transition: all 0.5s ease 0s;
  width: 30px;
}

.ring-alo-phone.ring-alo-green .ring-alo-ph-img-circle {
  background-color: #f00;
}

.ring-alo-phone.ring-alo-green .ring-alo-ph-img-circle {
  background-color: #f00;
}

.ring-alo-ph-img-circle {
  animation: 1s ease-in-out 0s normal none infinite running
    ring-alo-circle-img-anim;
  border: 2px solid transparent;
  border-radius: 100%;
  height: 30px;
  left: 30px;
  opacity: 1;
  position: absolute;
  top: 33px;
  transform-origin: 50% 50% 0;
  width: 30px;
}

.fix_tel a {
  text-decoration: none;
}

.ring-alo-ph-img-circle a img {
  padding: 1px 0 0 1px;
  width: 25px;
  position: relative;
}

.tel {
  background: #eee;
  width: 205px;
  height: 40px;
  position: relative;
  overflow: hidden;
  background-size: 40px;
  border-radius: 28px;
  border: solid 1px #ccc;
}

.tel {
  background-color: #eee;
}

.fone {
  color: #f00;
}

.fone {
  font-size: 20px;
  color: #f00;
  line-height: 40px;
  font-weight: bold;
  padding-left: 45px;
}

#contact-map {
  width: 100%;
  height: 400px;
}

.embed-responsive {
  position: unset !important;
}

@media only screen and (max-width: 767px) {
  #contact-map {
    height: 150px !important;
  }

  .fix_tel {
    bottom: 5px;
  }

  .ring-alo-phone {
    width: 10px;
  }

  .tel {
    width: auto;
    padding-right: 15px;
  }

  .fone {
    font-size: 14px;
  }

  section {
    padding: 10px 0;
  }

  .has-sub {
    position: relative !important;
  }

  .scroll-to-top {
    margin-bottom: 50px !important;
  }

  .ring-alo-ph-img-circle {
    left: 14px !important;
  }
}

.padding-10px-left {
  padding-left: 10px;
}

.vertical-align-top {
  vertical-align: top;
}

.color-705996 {
  font-weight: 700;
  color: #705996;
}

.custom-blogs {
  background: #fafafa !important;
  margin-top: 0 !important;
  min-height: 400px;
}
